import { SuccessToast } from "components/utils/Toast";
import { useAuth, useMutation } from "hooks/useBridge";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { API } from "sdk";
import { useForm } from "hooks/useForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useController } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ExclamationIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { QuestionMarkIcon } from "@radix-ui/react-icons";

export const CreateDiscount = () => {
  const { t } = useTranslation("discounts");
  const tokenizedCreateDiscount = useAuth(API.discount.create);

  const navigate = useNavigate();
  const { mutate: createDiscount } = useMutation(tokenizedCreateDiscount, {
    onSuccess: (res) => {
      reset({ body: {} });
      toast.custom((toast) => <SuccessToast t={toast} text={t("Discount created")} />);
      navigate("/discounts");
    },
  });

  const { register, handleSubmit, errors, control, reset, watch } = useForm(createDiscount, {
    defaultValues: {},
    resolver: (data, context, options) => {
      if (data.body.amount)
        data.body.amount =
          data.body.type === "percentage"
            ? parseFloat(data.body.amount as any)
            : Math.round(parseFloat(data.body.amount as any) * 100);
      if (data.body.minOrderSize) data.body.minOrderSize = Math.round(parseFloat(data.body.minOrderSize as any) * 100);
      return zodResolver(z.object({ body: API.discount.zodSchemas.create.body }))(data, context, options);
    },
  });

  const { field } = useController({ name: "body.type", control });
  const { field: bodyName } = useController({ name: "body.name", control });
  const { field: bodyTag } = useController({ name: "body.tag", control });
  const { field: bodyMinOrderSize } = useController({
    name: "body.minOrderSize",
    control,
  });
  const { field: bodyAmount } = useController({ name: "body.amount", control });

  const { value: displayType, onChange: setType } = field;
  const { value: displayName } = bodyName;
  const { value: displayTag } = bodyTag;
  const { value: displayMinOrderSize } = bodyMinOrderSize;
  const { value: displayAmount } = bodyAmount;

  return (
    <div className="relative w-full max-h-screen ">
      <form className="" onSubmit={handleSubmit} id="mainForm" />
      <div className="flex items-end mt-8">
        <p className="px-8 text-4xl font-bold">{t("Create a discount code")}</p>
        <div className="flex flex-row gap-4 ml-auto">
          <Link
            to="/discounts"
            className="relative flex justify-center px-4 py-2 ml-auto text-sm font-medium bg-white border border-gray-500 rounded-md text-main group hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
          >
            {t("Cancel")}
          </Link>
          <button
            type="submit"
            form="mainForm"
            className="relative flex justify-center px-4 py-2 ml-auto mr-4 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
          >
            {t("Create discount")}
          </button>
        </div>
      </div>
      <div className="grid items-start grid-cols-12 gap-8 px-8 py-4 mx-auto gap-y-2">
        <div className="col-span-8">
          <div className="flex flex-col p-8 mt-4 bg-white border border-gray-200 rounded-md">
            <p className="text-base font-bold">{t("Discount code")}</p>
            <input
              id="name"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("ex: MAMA10")}
              {...register("body.name")}
              form="mainForm"
            />
            <p className="mt-1 text-sm text-gray-600">{t("This code will be used by the clients")}.</p>
            <p className="mt-4 text-base font-bold">{t("Discount tag")}</p>
            <input
              id="tag"
              className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("ex: PROMO10")}
              {...register("body.tag")}
              form="mainForm"
            />
            <div className="flex items-center mt-1">
              <QuestionMarkCircleIcon className="w-3 h-3 text-yellow-500 " />
              <p className="ml-1 text-sm text-yellow-500">{t("A user cannot use two discounts with the same tag")}.</p>
            </div>
          </div>
          <div className="flex flex-col p-8 mt-4 bg-white border border-gray-200 rounded-md">
            <p className="font-bold text-gray-900">{t("Discount type")}</p>
            <div className="flex flex-row">
              <div className="flex items-start flex-1 mt-2 mb-2 cursor-pointer">
                <div className="flex items-center h-5">
                  <input
                    className="w-4 h-4 border-gray-300 rounded text-main focus:ring-main"
                    {...register("body.type")}
                    id="percentage"
                    name="body.type"
                    value="percentage"
                    type="radio"
                  />
                </div>
                <label htmlFor="percentage">
                  <div className="ml-3 text-sm">
                    <p className="">{t("Percentage")}</p>
                  </div>
                </label>
              </div>
              <div className="flex items-start flex-1 mt-2 mb-2 cursor-pointer">
                <div className="flex items-center h-5">
                  <input
                    className="w-4 h-4 border-gray-300 rounded text-main focus:ring-main"
                    {...register("body.type")}
                    id="coupon"
                    name="body.type"
                    value="coupon"
                    type="radio"
                  />
                </div>
                <label htmlFor="coupon" className="cursor-pointer">
                  <div className="ml-3 text-sm">
                    <p className="">{t("Coupon")}</p>
                  </div>
                </label>
              </div>
            </div>

            <p className="mt-6 font-bold text-gray-900">{t("Amount")}</p>
            <div className="flex">
              <input
                id="name"
                className="relative block w-1/2 px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md rounded-r-none appearance-none rounded-l-md focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                placeholder={t("Amount")}
                {...register("body.amount")}
                form="mainForm"
              />
              <div className="flex w-12 px-4 py-2 mt-2 text-center bg-gray-100 border-t border-b border-r border-gray-300 rounded-r-md">
                {displayType === "coupon" ? "€" : "%"}
              </div>
            </div>
          </div>
          <div className="flex flex-col p-8 mt-4 bg-white border border-gray-200 rounded-md">
            <p className="font-bold text-gray-900">{t("Minimum order")}</p>
            <div className="flex">
              <input
                id="name"
                className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md rounded-r-none appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                placeholder={t("Minimum order")}
                {...register("body.minOrderSize")}
                form="mainForm"
              />
              <div className="flex w-12 px-4 py-2 mt-2 text-center bg-gray-100 border-t border-b border-r border-gray-300 rounded-r-md">
                €
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 p-8 mt-4 bg-white border border-gray-200 rounded-md">
          <p className="font-bold text-gray-900">{t("Resume")}</p>
          <ul className="mt-2">
            <li>
              {t("Discount name:")} <span className="font-semibold">{displayName}</span>{" "}
            </li>
            <li>
              {t("Discount tag:")} <span className="font-semibold">{displayTag}</span>{" "}
            </li>
            <li>
              {t("Discount: ")}{" "}
              <span className="font-medium">
                {displayAmount}
                {displayType === "percentage" ? "%" : "€"}
              </span>{" "}
            </li>
            <li>
              {t("Minimum order:")} <span className="font-medium">{displayMinOrderSize}</span>€
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
