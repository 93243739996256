import { Select } from "components/form/Select";
import { Overlay } from "components/utils/Overlay";
import { useAuth, useMutation, useQuery } from "hooks/useBridge";
import { useEffect, useState } from "react";
import { useForm } from "hooks/useForm";
import { useTranslation } from "react-i18next";
import { API } from "sdk";
import { getNextMonth } from "utils/Date";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { useController } from "react-hook-form";
import { Switch } from "@headlessui/react";

export const Home = () => {
  const { t } = useTranslation("common");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const numberOfActiveUsersAuth = useAuth(API.statistics.numberOActiveUsers);
  const numberOfNewUsersAuth = useAuth(API.statistics.numberOfNewUsers);
  const numberOfPaidOrdersAuth = useAuth(API.statistics.numberOfPaidOrders);
  const numberOfVisibleDishesAuth = useAuth(API.statistics.numberOfVisibleDishes);
  const revenueAuth = useAuth(API.statistics.revenue);

  const from = new Date(year, month, 1);
  const to = getNextMonth(new Date(from));

  const { data: numberOfActiveUsers } = useQuery(["numberOfActiveUsers", from, to], () =>
    numberOfActiveUsersAuth({ body: { from, to } })
  );
  const { data: numberOfPaidOrders } = useQuery(["numberOfPaidOrders", from, to], () =>
    numberOfPaidOrdersAuth({ body: { from, to } })
  );
  const { data: numberOfNewUsers } = useQuery(["numberOfNewUsers", from, to], () =>
    numberOfNewUsersAuth({ body: { from, to } })
  );
  const { data: cooks } = useQuery(["getChefs"], () => API.chef.getPage({ body: {}, query: {} }));
  const { data: revenue } = useQuery(["revenue", from, to], () => revenueAuth({ body: { from, to } }));

  const { data: numberOfVisibleDishes } = useQuery("numberOfVisibleDishes", () => numberOfVisibleDishesAuth({}));

  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  return (
    <div className="w-full px-8 mx-auto">
      <div className="flex items-end mt-8">
        <PlatformSettingsModal open={openSettingsModal} setOpen={setOpenSettingsModal} />
        <p className="text-4xl font-bold">{t("Hello MamaFood!")}</p>
        <button
          type="button"
          onClick={() => setOpenSettingsModal(true)}
          className="relative flex justify-center px-4 py-2 ml-auto text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Platform settings")}
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <div className="col-span-1 p-6 bg-white border border-gray-200 rounded-md">
          <p>Number of visible dishes</p>
          <p className="text-3xl font-bold">{numberOfVisibleDishes?.total || 0}</p>
        </div>
        <div className="col-span-1 p-6 bg-white border border-gray-200 rounded-md">
          <p>Number of visible cooks</p>
          <p className="text-3xl font-bold">{cooks?.total || 0}</p>
        </div>
      </div>
      <div className="flex items-end mt-8">
        <p className="text-3xl font-bold">{t("Previous period results")}</p>

        <div className="flex gap-4 ml-auto">
          <div className="w-32">
            <YearSelect year={year} setYear={setYear} />
          </div>
          <div className="w-32">
            <MonthSelect month={month} setMonth={setMonth} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <div className="col-span-1 p-6 bg-white border border-gray-200 rounded-md ">
          <p>Number of active user</p>
          <p className="mt-2 text-3xl font-bold">
            {numberOfActiveUsers?.total || 0} <span className="text-xl">users</span>
          </p>
        </div>
        <div className="col-span-1 p-6 bg-white border border-gray-200 rounded-md ">
          <p>Number of new users</p>
          <p className="mt-1 text-3xl font-bold">
            {numberOfNewUsers?.total || 0} <span className="text-xl">new users</span>
          </p>
        </div>
        <div className="col-span-1 p-6 bg-white border border-gray-200 rounded-md ">
          <p>Number of paid orders</p>
          <p className="mt-2 text-3xl font-bold">
            {numberOfPaidOrders?.total || 0} <span className="text-xl">new orders</span>
          </p>
        </div>
        <div className="col-span-1 p-6 bg-white border border-gray-200 rounded-md ">
          <p>Revenue</p>
          <p className="mt-2 text-3xl font-bold">€{((revenue?.totalPrice || 0) / 100).toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

const YearSelect = ({ year, setYear }: { year: number; setYear: (y: number) => void }) => {
  return (
    <Select
      options={[{ label: "2022", value: 2022 }]}
      selected={year}
      setSelected={setYear}
      placeholder={"Select year"}
    />
  );
};

const MonthSelect = ({ month, setMonth }: { month: number; setMonth: (y: number) => void }) => {
  return (
    <Select
      options={Array(12)
        .fill(0)
        .map((_, i) => i)
        .map((m) => ({ label: new Date(2022, m, 1).toLocaleDateString("en", { month: "long" }), value: m }))}
      selectedLabel={new Date(2022, month, 1).toLocaleDateString("en", { month: "long" })}
      selected={month}
      setSelected={setMonth}
      placeholder={"Select year"}
    />
  );
};

interface PlatformSettingsModalProps {
  open: boolean;
  setOpen: (b: boolean) => void;
}

const PlatformSettingsModal = ({ open, setOpen }: PlatformSettingsModalProps) => {
  const updatePlatformAuth = useAuth(API.general.update);

  const { data, refetch } = useQuery("general", API.general.get);

  const { mutate: updatePlatform } = useMutation(updatePlatformAuth, {
    onSuccess: () => {
      setOpen(false);
      refetch();
    },
  });

  useEffect(() => {
    if (data)
      reset({
        body: {
          deliveryRadius: data.deliveryRadius,
          storeActive: data.storeActive,
          deliveryFees: data.deliveryFees / 100,
          minAmountToHaveFreeDelivery: data.minAmountToHaveFreeDelivery / 100,
          freeDeliveryOnFirstOrder: data.freeDeliveryOnFirstOrder,
          removeOnboarding: data.removeOnboarding,
          appVersion: data.appVersion,
        },
      });
  }, [data]);

  const { register, reset, handleSubmit, errors, control } = useForm(updatePlatform, {
    resolver: (data, context, options) => {
      const updatedData = {
        body: {
          ...data.body,
          deliveryFees: Math.round(parseFloat((data.body.deliveryFees || 0) as any) * 100),
          minAmountToHaveFreeDelivery: Math.round(
            parseFloat((data.body.minAmountToHaveFreeDelivery || 0) as any) * 100
          ),
          deliveryRadius: parseFloat((data.body.deliveryRadius || 0) as any),
        },
      };
      return zodResolver(z.object({ body: API.general.zodSchemas.update.body }))(updatedData, context, options);
    },
    defaultValues: { body: {} },
  });

  const { field } = useController({ control, name: "body.freeDeliveryOnFirstOrder" });
  const { value: freeDeliveryOnFirstOrder, onChange: setFreeDeliveryOnFirstOrder } = field;
  const { field: fieldOnboarding } = useController({ control, name: "body.removeOnboarding" });
  const { value: removeOnboarding, onChange: setRemoveOnboarding } = fieldOnboarding;

  const { t } = useTranslation("home");
  console.log("errors", errors);
  return (
    <Overlay open={open} setOpen={setOpen}>
      <form className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4" id="mainForm" onSubmit={handleSubmit}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6q" id="modal-title">
              {t("Platform settings")}
            </h3>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex items-end mt-4">
            <p className="">{t("Oldest build version")}</p>
          </div>
          <input
            id="deliveryRadius"
            type="number"
            step="1"
            min={0}
            className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("12")}
            {...register("body.appVersion")}
            form="mainForm"
          />
          <div className="flex items-end mt-4">
            <p className="">{t("Delivery radius around the hub (in meters)")}</p>
          </div>
          <input
            id="deliveryRadius"
            type="number"
            step="1"
            min={0}
            className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Devliery radius around the hub (in meters)")}
            {...register("body.deliveryRadius")}
            form="mainForm"
          />
          <div className="flex items-end mt-4">
            <p className="">{t("Delivery fees (€)")}</p>
          </div>
          <input
            id="deliveryFee"
            type="number"
            step="0.01"
            min={0}
            className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Min amount to have free delivery")}
            {...register("body.deliveryFees")}
            form="mainForm"
          />
          <div className="flex items-end mt-4">
            <p className="">{t("Min amount to have free delivery (€)")}</p>
          </div>
          <input
            id="minAmountToHaveFreeDelivery"
            type="number"
            min={0}
            step="0.01"
            className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Min amount to have free delivery")}
            {...register("body.minAmountToHaveFreeDelivery")}
            form="mainForm"
          />
          <div className="flex items-center mt-4">
            <Switch
              checked={!!freeDeliveryOnFirstOrder}
              onChange={setFreeDeliveryOnFirstOrder}
              className={`${
                freeDeliveryOnFirstOrder ? "bg-main" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Free order on first delivery</span>
              <span
                className={`${
                  freeDeliveryOnFirstOrder ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            <p className="ml-4">Free delivery on first order</p>
          </div>
          <div className="flex items-center mt-4">
            <Switch
              checked={!!removeOnboarding}
              onChange={setRemoveOnboarding}
              className={`${
                removeOnboarding ? "bg-main" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Remove onbaording</span>
              <span
                className={`${
                  removeOnboarding ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            <p className="ml-4">Remove onbaording</p>
          </div>
        </div>
      </form>

      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          form="mainForm"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            reset();
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};
