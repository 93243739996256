import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type OrderT = SDKTypes['order'];

export class Order {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    updateByAdmin: {
      body: z
  .object({
    deliveryStatus: z
      .enum([
        "Waiting",
        "Created",
        "Dispatched",
        "Underway",
        "Pickup",
        "Arrived",
        "Completed",
      ])
      .optional(),
    prepared: z.boolean().optional(),
  })
  .strict(),
      query: z
  .object({
    orderId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getByUser: {
      query: z
  .object({
    orderId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getByAdmin: {
      query: z
  .object({
    orderId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getPageByAdmin: {
      body: z
  .object({
    username: z.string().optional(),
    deliveryStatus: z
      .enum([
        "Waiting",
        "Created",
        "Dispatched",
        "Underway",
        "Pickup",
        "Arrived",
        "Completed",
      ])
      .optional(),
  })
  .strict(),
    },
  };

  public updateByAdmin = (p: { body: OrderT['updateByAdmin']['body']; query: OrderT['updateByAdmin']['query']; headers: OrderT['updateByAdmin']['headers'] }): Promise<OrderT['updateByAdmin']['return']> => {
    return this.Fetch({ path: '/order/updateByAdmin', method: 'POST', ...p });
  };

  public getByUser = (p: { query: OrderT['getByUser']['query']; headers: OrderT['getByUser']['headers'] }): Promise<OrderT['getByUser']['return']> => {
    return this.Fetch({ path: '/order/getByUser', method: 'POST', ...p });
  };

  public getByAdmin = (p: { query: OrderT['getByAdmin']['query']; headers: OrderT['getByAdmin']['headers'] }): Promise<OrderT['getByAdmin']['return']> => {
    return this.Fetch({ path: '/order/getByAdmin', method: 'POST', ...p });
  };

  public getPageByUser = (p: { query: OrderT['getPageByUser']['query']; headers: OrderT['getPageByUser']['headers'] }): Promise<OrderT['getPageByUser']['return']> => {
    return this.Fetch({ path: '/order/getPageByUser', method: 'POST', ...p });
  };

  public getPageByAdmin = (p: { body: OrderT['getPageByAdmin']['body']; query: OrderT['getPageByAdmin']['query']; headers: OrderT['getPageByAdmin']['headers'] }): Promise<OrderT['getPageByAdmin']['return']> => {
    return this.Fetch({ path: '/order/getPageByAdmin', method: 'POST', ...p });
  };

  public getNotCompletedOrders = (p: { headers: OrderT['getNotCompletedOrders']['headers'] }): Promise<OrderT['getNotCompletedOrders']['return']> => {
    return this.Fetch({ path: '/order/getNotCompletedOrders', method: 'POST', ...p });
  };
}
