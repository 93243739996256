import { LockClosedIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { API } from "sdk";
import { useMutation } from "hooks/useBridge";
import { useGlobalState } from "hooks/useGlobalState";
import { useEffect } from "react";
import { useForm } from "hooks/useForm";
import { setItem } from "hooks/useLocalStorage";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const Signin = () => {
  const { t } = useTranslation("signin");
  const navigate = useNavigate();

  const [admin, setAdmin] = useGlobalState("admin");

  const { mutate, error, isLoading } = useMutation(API.admin.signin, {
    onSuccess: (res) => {
      setItem("accessToken", res.accessToken);
      setItem("refreshToken", res.refreshToken);
      setAdmin({ isAuthenticated: true, ...res });
      navigate("/");
    },
  });

  const { register, handleSubmit } = useForm(mutate, {
    resolver: zodResolver(z.object({ body: API.admin.zodSchemas.signin.body })),
  });

  useEffect(() => {
    if (admin.isAuthenticated) navigate("/");
  });

  return (
    <div className="flex w-full max-h-screen ">
      <div className="flex flex-1">
        <img src={require("assets/signin.jpg")} className="object-cover w-full h-screen" />
      </div>
      <div className="flex items-center justify-center flex-1 min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h2 className="mt-6 text-4xl font-extrabold">{t("Signin description")}</h2>
            <p className="mt-4 text-gray-700">{t("Enter your credentials to access the admin panel")}</p>
          </div>{" "}
          {error && error.error.name === "Document not found" && (
            <p className="p-4 mb-2 text-sm text-red-500 rounded-md bg-red-50">
              {t("There is no account linked to this email")}
            </p>
          )}
          {error && error.error.name === "Invalid password" && (
            <p className="p-4 mb-2 text-sm text-red-500 rounded-md bg-red-50">{t("Wrong password")}</p>
          )}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md">
              <div>
                <label htmlFor="email-address" className="font-semibold">
                  {t("Email address")}
                </label>
                <input
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full px-3 py-2 mt-1 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                  placeholder={t("Email address")}
                  {...register("body.email")}
                />
              </div>
              <div>
                <label htmlFor="password" className="font-semibold">
                  {t("Password")}
                </label>
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                  placeholder={t("Password")}
                  {...register("body.password")}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-mainDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
              >
                <span className="absolute left-0 flex items-center pl-3 insets-y-0">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <LockClosedIcon className="w-5 h-5 text-main group-hover:text-mainLight" aria-hidden="true" />
                  )}
                </span>
                {t("Sign in")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const Spinner = () => {
  return (
    <svg
      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
