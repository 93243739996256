import z from "zod";
import { useState } from "react";

import { Link, useParams } from "react-router-dom";
import { ExclamationIcon, TrashIcon } from "@heroicons/react/outline";
import { Overlay } from "components/utils/Overlay";

import { useQuery, useAuth, useMutation } from "hooks/useBridge";
import { useForm } from "hooks/useForm";

import { useTranslation } from "react-i18next";
import { useController } from "react-hook-form";

import { API } from "sdk";

import { zodResolver } from "@hookform/resolvers/zod";

import { extract } from "../../utils/Object";
import { InputCoverImage, InputProfileImage } from "components/form/InputImage";
import { StarIcon } from "@heroicons/react/solid";
import { getDayOfWeekString } from "utils/Date";
import { t } from "i18next";
import _ from "lodash";
import defaultChef from "assets/defaultChef.png";
import { ChefType } from "types/api/Chef";
import { APIType } from "api";

type t = APIType["chef"]["updateByAdmin"]["Data"]["body"];
export const Chef = () => {
  const getByAdmin = useAuth(API.chef.getByAdmin);
  const { id } = useParams();
  const { data: chef, refetch } = useQuery(["chef", id], () => getByAdmin({ query: { chefId: id || "" } }));

  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const { t } = useTranslation("chef");

  if (!chef) return <div />;

  return (
    <div className="relative -mt-8">
      <div className="w-full bg-white border-b border-gray-200">
        <div className="flex items-center max-w-5xl grid-cols-12 gap-8 px-4 py-8 mx-auto mt-4">
          {chef.profileImage ? (
            <img
              src={chef.profileImage}
              className="flex-shrink-0 object-cover w-24 h-24 border-2 border-white rounded-full"
            />
          ) : (
            <img className="object-cover w-24 h-24 rounded-full" src={defaultChef} alt="" />
          )}
          <div className="flex flex-col ">
            <p className="text-lg font-bold">
              {chef.firstName} {chef.lastName}
            </p>
            <div className="flex">{chef.stars ? <Stars stars={chef.stars} /> : null}</div>
            <p className="whitespace-pre-line line-clamp-4">{chef.description}</p>
          </div>
          <div className="ml-auto">
            <div className="flex items-center flex-shrink-0 mt-2">
              <button
                type="button"
                onClick={() => setEditProfileOpen(true)}
                className="inline-flex justify-center w-full px-4 py-2 ml-auto text-base font-medium text-white border border-transparent rounded-md whitespace-nowrap bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:w-auto sm:text-sm"
              >
                {t("Edit profile")}
              </button>
            </div>
            <EditProfileModal open={editProfileOpen} setOpen={setEditProfileOpen} chef={chef} refetch={refetch} />
          </div>
        </div>
      </div>
      <div className="relative z-10 grid items-start max-w-5xl grid-cols-12 gap-8 mx-auto mt-8">
        <div className="flex flex-col col-span-4 p-4 bg-white border border-gray-200 rounded-md ">
          <p className="font-bold ">{t("Chef's availabilities")}</p>
          <div className="w-8 border-t-2 rounded-md border-main" />
          <Availibilities chef={chef} refetch={refetch} />
        </div>
        <div className="flex flex-col col-span-8 py-4 bg-white border border-gray-200 rounded-md flex-0">
          <p className="px-4 font-bold ">{t("Chef's dishes")}</p>
          <div className="w-8 ml-4 border-t-2 rounded-md border-main" />
          <DishesTable
            dishes={chef.dishes.sort((a, b) => (a.positionVisibility || Infinity) - (b.positionVisibility || Infinity))}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  );
};

const Availibilities = ({ chef, refetch }: { chef: ChefType; refetch: () => any }) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <div className="">
      <EditAvailabilitiesModal chef={chef} open={open} setOpen={setOpen} refetch={refetch} />
      <div className="flex flex-wrap gap-2 mt-4">
        {chef.disponibilityDays?.map((d) => (
          <p className="p-1 px-2 border border-gray-200 rounded-md bg-gray-50">
            {getDayOfWeekString(d, i18n.language)}
          </p>
        ))}
        {(!chef.disponibilityDays || chef.disponibilityDays.length == 0) && (
          <p className="mt-2">{t("No availabilities")}</p>
        )}
      </div>
      {chef.disponibilityString && (
        <div>
          <p className="mt-4 font-semibold">{t("Additional")}</p>
          <p className="mt-2">{chef.disponibilityString}</p>
        </div>
      )}
      <p className="mt-4 font-semibold cursor-pointer text-main" onClick={() => setOpen(true)}>
        {t("Edit availabilities")}
      </p>
    </div>
  );
};

interface EditAvailabilitiesModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  chef: ChefType;
  refetch: () => any;
}

const EditAvailabilitiesModal = ({ open, setOpen, chef, refetch }: EditAvailabilitiesModalProps) => {
  const { t, i18n } = useTranslation("chef");
  const tokenizedUpdateChef = useAuth(API.chef.updateByAdmin);

  const { mutate: updateAvailabilities } = useMutation(tokenizedUpdateChef, {
    onSuccess: () => {
      setOpen(false);
      refetch();
    },
  });

  const { register, handleSubmit, reset, control } = useForm(updateAvailabilities, {
    resolver: zodResolver(
      z.object({ body: API.chef.zodSchemas.updateByAdmin.body, query: z.object({ chefId: z.string() }) })
    ),
    defaultValues: { body: extract(chef, ["disponibilityString", "disponibilityDays"]), query: { chefId: chef._id } },
  });

  const { field } = useController({ name: "body.disponibilityDays", control });
  const { value: disponibilityDays, onChange: setDisponibilityDays } = field;

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4" id="edit-profile" onSubmit={handleSubmit}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6q" id="modal-title">
              {t("Edit availabilities")}: {chef.firstName} {chef.lastName}
            </h3>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="first-name" className="mt-4 font-semibold ">
            {t("Availibilities")}
          </label>
          <div className="flex flex-wrap gap-2 mt-2">
            {([1, 2, 3, 4, 5, 6, 0] as const).map((d) => (
              <p
                className={`p-1 px-2 cursor-pointer border  rounded-md ${
                  disponibilityDays?.includes(d) ? "bg-main text-white" : "bg-gray-50 border-gray-200"
                }`}
                onClick={() =>
                  setDisponibilityDays(
                    disponibilityDays?.includes(d)
                      ? disponibilityDays.filter((d2) => d2 !== d)
                      : [...(disponibilityDays || []), d]
                  )
                }
              >
                {getDayOfWeekString(d, i18n.language)}
              </p>
            ))}
          </div>
          <textarea
            id="availabilities"
            rows={5}
            className="block w-full mt-4 border border-gray-300 rounded-md shadow-sm focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("Availibilities")}
            defaultValue={""}
            {...register("body.disponibilityString")}
          />
        </div>
      </form>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          form="edit-profile"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            reset();
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

interface EditProfileModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  chef: ChefType;
  refetch: () => any;
}

const EditProfileModal = ({ open, setOpen, chef, refetch }: EditProfileModalProps) => {
  const { t } = useTranslation("chef");
  const tokenizedUpdateByAdmin = useAuth(API.chef.updateByAdmin);
  const tokenizedUpdateImages = useAuth(API.chef.updateImages);
  const [deletedCoverImage, setDeletedCoverImage] = useState(false);
  const [deletedProfileImage, setDeletedProfileImage] = useState(false);

  const { mutate: updateChef } = useMutation(tokenizedUpdateByAdmin, {
    onSuccess: () => {
      const updateData = {} as any;
      if (profileImage) updateData.profileImage = profileImage;
      if (coverImage) updateData.coverImage = coverImage;
      if (profileImage || coverImage) updateImages({ query: { chefId: chef._id }, files: updateData });
      else {
        setOpen(false);
        refetch();
      }
    },
  });

  const { mutate: updateImages } = useMutation(tokenizedUpdateImages, {
    onSuccess: () => {
      setOpen(false);
      refetch();
    },
  });

  const { register, handleSubmit, reset, errors } = useForm(updateChef, {
    resolver: (data, context, options) => {
      const nData = { body: { ...data.body }, query: { ...data.query } };
      if (nData.body?.stars) nData.body.stars = parseFloat(nData.body.stars as any) * 20;
      if (nData.body?.numberOfVotes) nData.body.numberOfVotes = parseFloat(nData.body.numberOfVotes as any);
      if (deletedCoverImage) nData.body.coverImage = "";
      if (deletedProfileImage) nData.body.profileImage = "";
      return zodResolver(
        z.object({ body: API.chef.zodSchemas.updateByAdmin.body, query: z.object({ chefId: z.string() }) })
      )(nData, context, options);
    },
    defaultValues: {
      body: {
        ...extract(chef, [
          "firstName",
          "lastName",
          "disponibilityString",
          "address",
          "phone",
          "description",
          "numberOfVotes",
        ]),
        stars: (chef.stars || 0) / 20,
      },
      query: { chefId: chef._id },
    },
  });

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4" id="edit-profile" onSubmit={handleSubmit}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6q" id="modal-title">
              {t("Edit profile")}: {chef.firstName} {chef.lastName}
            </h3>
          </div>
        </div>
        <div className="mt-4 ">
          <InputCoverImage
            image={coverImage}
            setImage={setCoverImage}
            initialImage={chef.coverImage}
            deleted={deletedCoverImage}
            setDeleted={setDeletedCoverImage}
          />
          <div className="relative ml-4 -mt-16">
            <InputProfileImage
              image={profileImage}
              setImage={setProfileImage}
              initialImage={chef.profileImage}
              deleted={deletedProfileImage}
              setDeleted={setDeletedProfileImage}
            />
            {((chef.profileImage && !deletedProfileImage) || profileImage) && (
              <TrashIcon
                className="absolute p-1 text-white bg-red-500 border-2 border-red-500 rounded-full cursor-pointer hover:border-white top-2 left-16 w-7 h-7"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setProfileImage(null);
                  setDeletedProfileImage(true);
                }}
              />
            )}
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="mt-4 font-semibold ">
              {t("First name")}
            </label>
            <input
              id="first-name"
              type="text"
              autoComplete="firstName"
              required
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("First name")}
              {...register("body.firstName")}
            />
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="mt-4 font-semibold ">
              {t("Last name")}
            </label>
            <input
              id="last-name"
              type="text"
              autoComplete="lastName"
              required
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Last name")}
              {...register("body.lastName")}
            />
          </div>
        </div>{" "}
        <div className="mt-4">
          <label htmlFor="description" className="mt-4 font-semibold ">
            {t("Description")}
          </label>
          <div className="flex gap-4 mt-2">
            <textarea
              id="description"
              rows={5}
              className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-main focus:border-main sm:text-sm"
              placeholder={t("Description")}
              defaultValue={""}
              {...register("body.description")}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="mt-4 font-semibold ">
              {t("Phone number")}
            </label>
            <input
              id="phone-number"
              type="text"
              autoComplete="phoneNumber"
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Phone number")}
              {...register("body.phone")}
            />
          </div>
        </div>
        <div className="flex gap-4 ">
          <div className="flex flex-col flex-1 mb-8">
            <label htmlFor="first-name" className="mt-4 font-semibold ">
              {t("Rating")}
            </label>
            <input
              id="rate"
              type="number"
              autoComplete="rating"
              step={0.1}
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Rating")}
              {...register("body.stars")}
              max="5"
              min="0"
            />
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="first-name" className="mt-4 font-semibold ">
              {t("Number of votes")}
            </label>
            <input
              id="reviews"
              type="number"
              autoComplete="rating"
              step={1}
              className="relative block w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
              placeholder={t("Number of votes")}
              {...register("body.numberOfVotes")}
              min="0"
            />
          </div>
        </div>
      </form>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          form="edit-profile"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            reset();
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

interface ConfirmDeletionModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  dish: ChefType["dishes"][number];
  refetch: () => {};
}

const ConfirmDeletionModal = ({ open, setOpen, dish, refetch }: ConfirmDeletionModalProps) => {
  const { t } = useTranslation("chef");

  const deleteByAdminWithToken = useAuth(API.dish.deleteByAdmin);

  const { mutate: deleteByAdmin } = useMutation(deleteByAdminWithToken, {
    onSuccess: (res) => {
      setOpen(false);
      refetch();
    },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-8 h-8 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("DeleteDish")}: {dish.name || dish.byChef.name}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t("DeleteExplanation")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => {
            deleteByAdmin({ query: { dishId: dish._id } });
          }}
        >
          {t("Delete")}
        </button>
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

const DishRow = ({ dish, refetch }: { dish: ChefType["dishes"][number]; refetch: () => {} }) => {
  const { t } = useTranslation("chef");
  const [openDelete, setOpenDelete] = useState(false);

  const authentifiedUpdateDishByAdmin = useAuth(API.dish.updateByAdmin);
  const { mutate: updateDishByAdmin } = useMutation(authentifiedUpdateDishByAdmin, {
    onSuccess: () => refetch(),
  });

  const visible = dish.visibleInApp && dish.stock && dish.stock > 0;

  return (
    <div className="grid w-full grid-cols-12 gap-4 px-4 py-2 border-b border-gray-50">
      <div className="flex items-center col-span-6 gap-4">
        <div className="relative flex-shrink-0">
          {dish.image ? (
            <img src={dish.image} className="flex-shrink-0 object-cover w-16 h-16 rounded-md" />
          ) : (
            <div className="flex-shrink-0 w-16 h-16 rounded-md bg-mainLight bg-opacity-20" />
          )}
          <div
            className={`w-3 absolute h-3 -top-1 -right-1 border border-white rounded-full ${
              visible ? "bg-green-500" : "bg-red-500"
            }`}
          />
        </div>
        <Link className="flex flex-col flex-0" to={`/dishes/${dish._id}`}>
          {dish.deletedByChef ? (
            <p className="font-bold text-red-700">
              {t("Deleted by cook")}: {dish.name || dish.byChef.name}
            </p>
          ) : dish.name ? (
            <p className="font-bold ">{dish.name}</p>
          ) : (
            <p className="text-sm font-bold text-red-600 uppercase">{t("To be completed")}</p>
          )}
          <p className="flex flex-1 text-sm text-gray-500 text text-ellipsis line-clamp-2">{dish.description}</p>
        </Link>
      </div>
      <div className="flex items-center col-span-1">
        <p className="">{dish.stock}</p>
      </div>
      <div className="flex items-center justify-end col-span-2 text-right">
        <p className="">{dish.category}</p>
      </div>
      <div className="flex items-center justify-end col-span-2 text-right">
        <select
          id="position"
          name="position"
          autoComplete="position"
          onChange={(e) =>
            updateDishByAdmin({ body: { positionVisibility: parseInt(e.target.value) }, query: { dishId: dish._id } })
          }
          defaultValue={dish.positionVisibility}
          className="block w-12 px-2 py-1 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-main focus:border-main sm:text-sm"
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </select>
      </div>
      <div className="flex items-center justify-end col-span-1">
        <ConfirmDeletionModal open={openDelete} setOpen={setOpenDelete} dish={dish} refetch={refetch} />
        <TrashIcon
          className="w-8 p-1 text-gray-600 border-2 border-transparent rounded-md cursor-pointer bg-gray-50 hover:border-gray-200"
          onClick={() => setOpenDelete(true)}
        />
      </div>
    </div>
  );
};

export const DishesTable = ({ dishes, refetch }: { dishes: ChefType["dishes"][number][]; refetch: () => any }) => {
  const { t } = useTranslation("chef");
  const { t: tType } = useTranslation("dishTypes");
  // const [reorderedDishes, setReorederedDishes] = useState(dishes.map((d) => ({ id: d._id, ...d })));
  return (
    <div className="">
      <div className="grid grid-cols-12 gap-4 px-4 pt-2 mt-4 border-b border-gray-50">
        <div className="flex col-span-6 ">
          <div className="w-16 col-span-2 ">
            <p className="text-sm font-bold uppercase">{t("Image")}</p>
          </div>
          <div className="flex flex-1 col-span-6 ml-4 ">
            <p className="text-sm font-bold uppercase">{t("Details")}</p>
          </div>
        </div>
        <div className="col-span-1">
          <p className="text-sm font-bold uppercase">{t("Stock")}</p>
        </div>
        <div className="flex justify-end col-span-2">
          <p className="text-sm font-bold uppercase">{t("Category")}</p>
        </div>
        <div className="flex justify-end col-span-2">
          <p className="text-sm font-bold uppercase">{t("Pos.")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-sm font-bold uppercase">{t("Del.")}</p>
        </div>
      </div>
      {dishes.map((dish) => (
        <DishRow dish={dish} key={dish._id} refetch={refetch} />
      ))}
    </div>
  );
};

const Star = ({ pos, stars }: { pos: number; stars: number }) => {
  return (
    <div style={{ width: Math.min(stars / 20 - pos, 1) * 24 }} className="z-50 overflow-hidden">
      <StarIcon className="w-6 h-6 text-main" />
    </div>
  );
};

const Stars = ({ stars }: { stars: number }) => {
  return (
    <div className="relative flex">
      <div className="absolute top-0 z-0 flex">
        {_.range(0, 5).map(() => (
          <StarIcon className="w-6 h-6 text-gray-300" />
        ))}
      </div>
      {_.range(0, Math.ceil(stars / 20)).map((_, i) => (
        <Star pos={i} stars={stars} />
      ))}
    </div>
  );
};
