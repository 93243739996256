import { ExclamationIcon, SearchIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { APIType } from "api";
import { Overlay } from "components/utils/Overlay";
import { useQuery } from "hooks/useBridge";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { API } from "sdk";
import { useAuth, useMutation } from "hooks/useBridge";
import _ from "lodash";
import { MultipleSelect } from "components/form/Select";
import { CATEGORIES } from "data/Categories";
import { DIETS } from "data/Diets";
import { DISHTYPES } from "data/DishTypes";
import { Pagination } from "components/utils/Pagination";
import { SortAscendingIcon, SortDescendingIcon } from "@heroicons/react/solid";
import { useForm } from "hooks/useForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

type DishesType = APIType["dish"]["getPageByAdmin"]["NoErrorsReturn"];
type DishType = DishesType["data"][number];

type GetDishesFilters = APIType["dish"]["getPageByAdmin"]["NoHeadersData"];

export const Dishes = () => {
  const { t } = useTranslation("dishes");
  const perPage = 25;
  const [queryFilters, setQueryFilters] = useState<GetDishesFilters>({
    body: {},
    query: { sortOnStock: "-1", skip: "0", limit: perPage.toString() },
  });
  const getDishes = useAuth(API.dish.getPageByAdmin);

  const { data: dishes, refetch } = useQuery(["dishesHome", queryFilters], () => getDishes(queryFilters), {
    keepPreviousData: true,
  });

  const page = ((queryFilters.query.skip || 0) as number) / perPage;
  const setPage = (p: number) =>
    setQueryFilters((qF) => ({
      ...qF,
      query: {
        ...qF.query,
        skip: (p * perPage).toString(),
        limit: perPage.toString(),
      },
    }));

  return (
    <div className="w-full px-8 mx-auto">
      <div className="flex items-end mt-8">
        <p className="text-4xl font-bold">{t("Dish listing")}</p>
        <p className="ml-4 text-lg text-gray-500">
          {dishes?.total} {t("items")}
        </p>
      </div>
      <div className="p-6 mt-4 bg-white border border-gray-200 rounded-md">
        {dishes && (
          <DishesTable
            dishes={dishes}
            refetch={refetch}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            page={page}
            setPage={setPage}
            total={Math.ceil(dishes.total / perPage)}
          />
        )}
      </div>
    </div>
  );
};

const DishRow = ({ dish, refetch }: { dish: DishType; refetch: () => {} }) => {
  const { t } = useTranslation("dishes");
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const visible = dish.visibleInApp && dish.stock && dish.stock > 0;

  return (
    <div className="grid w-full grid-cols-12 gap-4 py-2 border-b border-gray-50">
      <UpdateStockModal open={openUpdate} setOpen={setOpenUpdate} dish={dish} refetch={refetch} />
      <div className="flex items-center col-span-5 gap-4">
        <div className="relative flex-shrink-0">
          {dish.image ? (
            <img src={dish.image} className="flex-shrink-0 object-cover w-16 h-16 rounded-md" />
          ) : (
            <div className="flex-shrink-0 w-16 h-16 rounded-md bg-mainLight bg-opacity-20" />
          )}
          <div
            className={`w-3 absolute h-3 -top-1 -right-1 border border-white rounded-full ${
              visible ? "bg-green-500" : "bg-red-500"
            }`}
          />
        </div>
        <Link className="flex flex-col flex-0" to={`/dishes/${dish._id}`}>
          {dish.deletedByChef ? (
            <p className="font-bold text-red-700">
              {t("Deleted by cook")}: {dish.name || dish.byChef.name}
            </p>
          ) : dish.name ? (
            <p className="font-bold ">{dish.name}</p>
          ) : (
            <p className="text-sm font-bold text-red-600 uppercase">{t("To be completed")}</p>
          )}
          <p className="flex flex-1 text-sm text-gray-500 text text-ellipsis line-clamp-2">{dish.description}</p>
        </Link>
      </div>
      <Link className="flex items-center col-span-2 text-sm" to={`/chefs/${dish.chef._id}`}>
        <p className="text-sm text-main hover:underline">
          {dish.chef.firstName} {dish.chef.lastName}
        </p>
      </Link>
      <div className="relative flex items-center cursor-pointer " onClick={() => setOpenUpdate(true)}>
        <p className="px-1 py-1 text-sm rounded-md hover:border-main hover:border ">{dish.stock}</p>
      </div>
      <div className="relative flex items-center">
        <p className="px-1 py-1 text-sm rounded-md ">{dish.PLU}</p>
      </div>
      <div className="flex items-center justify-end col-span-2 text-sm text-right">
        <p className="">{dish.category}</p>
      </div>
      <div className="flex items-center justify-end col-span-1">
        <ConfirmDeletionModal open={openDelete} setOpen={setOpenDelete} dish={dish} refetch={refetch} />
        <TrashIcon
          className="w-8 p-1 text-gray-600 border-2 border-transparent rounded-md cursor-pointer bg-gray-50 hover:border-gray-200"
          onClick={() => setOpenDelete(true)}
        />
      </div>
    </div>
  );
};

export const DishesTable = ({
  dishes,
  refetch,
  queryFilters,
  setQueryFilters,
  page,
  setPage,
  total,
}: {
  dishes: DishesType;
  refetch: () => {};
  queryFilters: GetDishesFilters;
  setQueryFilters: Dispatch<SetStateAction<GetDishesFilters>>;
  page: number;
  setPage: (page: number) => any;
  total: number;
}) => {
  const { t } = useTranslation("dishes");
  const setBodyFilter = <Key extends keyof GetDishesFilters["body"]>(key: Key, value: GetDishesFilters["body"][Key]) =>
    setQueryFilters((qF) => ({ ...qF, body: { ...qF.body, [key]: value } }));

  const setQueryFilter = <Key extends keyof GetDishesFilters["query"]>(
    key: Key,
    value: GetDishesFilters["query"][Key]
  ) => setQueryFilters((qF) => ({ ...qF, query: { ...qF.query, [key]: value } }));

  const { current: debouncedSetQueryFilters } = useRef(_.debounce(setBodyFilter, 300));

  const setCategory = (S: typeof CATEGORIES[number][] | undefined) => {
    setBodyFilter("category", S);
  };

  const setDiet = (S: typeof DIETS[number][] | undefined) => {
    setBodyFilter("diet", S);
  };

  const setDishType = (S: typeof DISHTYPES[number][] | undefined) => {
    setBodyFilter("dishType", S);
  };

  const getNextSort = (c: "1" | "-1" | undefined) => {
    return !c ? "-1" : c === "-1" ? "1" : undefined;
  };

  const sortOns = ["sortOnChefPosition", "sortOnStock"] as const;
  const sortOn = (on: typeof sortOns[number]) => {
    sortOns.filter((o) => o !== on).forEach((o) => setQueryFilter(o, undefined));
    setQueryFilter(on, getNextSort(queryFilters.query[on]));
  };

  return (
    <div className="">
      <div>
        <div className="flex">
          <div className="relative">
            <input
              id="chefName"
              type="text"
              className="relative block w-64 px-3 py-2 pl-10 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md appearance-none h-9 focus:outline-none focus:ring-main focus:border-main sm:text-sm"
              placeholder={t("Search a chef")}
              onChange={(e) => debouncedSetQueryFilters("chefName", e.target.value)}
            />
            <SearchIcon className="absolute w-5 text-gray-500 top-2 left-3" />
          </div>
          <div className="relative ml-2">
            <input
              id="textSearch"
              type="text"
              className="relative block w-64 px-3 py-2 pl-10 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md appearance-none h-9 focus:outline-none focus:ring-main focus:border-main sm:text-sm"
              placeholder={t("Search a dish")}
              onChange={(e) => debouncedSetQueryFilters("textSearch", e.target.value)}
            />
            <SearchIcon className="absolute w-5 text-gray-500 top-2 left-3" />
          </div>
          <div className="flex gap-2 ml-auto">
            <DietSelect diet={queryFilters.body.diet} setDiet={setDiet} />
            <CategorySelect category={queryFilters.body.category} setCategory={setCategory} />
            <TypeSelect dishType={queryFilters.body.dishType} setDishType={setDishType} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {queryFilters.body.category?.map((c) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50">
            <p className="text-sm ">{c}</p>
            <XIcon
              className="w-6 h-6 p-1 ml-1 cursor-pointer hover:bg-gray-"
              onClick={() => setCategory(queryFilters.body.category?.filter((c2) => c2 !== c))}
            />
          </div>
        ))}
        {queryFilters.body.diet?.map((d) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50">
            <p className="text-sm ">{d}</p>
            <XIcon
              className="w-6 h-6 p-1 ml-1 cursor-pointer hover:bg-gray-"
              onClick={() => setDiet(queryFilters.body.diet?.filter((d2) => d2 !== d))}
            />
          </div>
        ))}
        {queryFilters.body.dishType?.map((d) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50">
            <p className="text-sm ">{d}</p>
            <XIcon
              className="w-6 h-6 p-1 ml-1 cursor-pointer hover:bg-gray-"
              onClick={() => setDishType(queryFilters.body.dishType?.filter((d2) => d2 !== d))}
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-12 gap-4 py-2 mt-4 border-b border-gray-50">
        <div className="flex col-span-5 ">
          <div className="w-16 col-span-2">
            <p className="text-xs font-bold uppercase">{t("Image")}</p>
          </div>
          <div className="flex flex-1 col-span-6 ml-4 ">
            <p className="text-xs font-bold uppercase">{t("Details")}</p>
          </div>
        </div>
        <div className="flex items-center col-span-2">
          <p className="text-xs font-bold uppercase">{t("Chef")}</p>
          <div className="ml-2 cursor-pointer" onClick={() => sortOn("sortOnChefPosition")}>
            {queryFilters.query.sortOnChefPosition === "1" ? (
              <SortAscendingIcon className="w-5 h-5 " />
            ) : queryFilters.query.sortOnChefPosition === "-1" ? (
              <SortDescendingIcon className="w-5 h-5" />
            ) : (
              <SortDescendingIcon className="w-5 h-5 text-gray-300" />
            )}
          </div>
        </div>
        <div className="flex col-span-1">
          <p className="text-xs font-bold uppercase">{t("Stock")}</p>
          <div className="ml-2 cursor-pointer" onClick={() => sortOn("sortOnStock")}>
            {queryFilters.query.sortOnStock === "1" ? (
              <SortAscendingIcon className="w-5 h-5 " />
            ) : queryFilters.query.sortOnStock === "-1" ? (
              <SortDescendingIcon className="w-5 h-5" />
            ) : (
              <SortDescendingIcon className="w-5 h-5 text-gray-300" />
            )}
          </div>
        </div>
        <div className="flex col-span-1">
          <p className="text-xs font-bold uppercase">{t("PLU")}</p>
        </div>
        <div className="flex justify-end col-span-2">
          <p className="text-xs font-bold uppercase">{t("Category")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-xs font-bold uppercase">{t("Actions")}</p>
        </div>
      </div>
      {dishes.data?.map((dish, i) => (
        <DishRow dish={dish} key={dish._id} refetch={refetch} />
      ))}
      <div className="flex items-center justify-center w-full">
        <Pagination page={page} setPage={setPage} total={total} />
      </div>
    </div>
  );
};

const CategorySelect = ({
  category,
  setCategory,
}: {
  category: typeof CATEGORIES[number][] | undefined;
  setCategory: (c: typeof CATEGORIES[number][] | undefined) => void;
}) => {
  const { t: tCategory } = useTranslation("categories");
  const { t } = useTranslation("dishes");

  return (
    <MultipleSelect
      options={CATEGORIES.map((c) => ({ value: c, label: tCategory(c) } as const))}
      selected={category}
      setSelected={setCategory}
      placeholder={t("Select categories")}
    />
  );
};

const DietSelect = ({
  diet,
  setDiet,
}: {
  diet: typeof DIETS[number][] | undefined;
  setDiet: (c: typeof DIETS[number][] | undefined) => void;
}) => {
  const { t: tDiet } = useTranslation("diets");
  const { t } = useTranslation("dishes");

  return (
    <MultipleSelect
      options={DIETS.map((d) => ({ value: d, label: tDiet(d) } as const))}
      selected={diet}
      setSelected={setDiet}
      placeholder={t("Select diets")}
    />
  );
};

const TypeSelect = ({
  dishType,
  setDishType,
}: {
  dishType: typeof DISHTYPES[number][] | undefined;
  setDishType: (c: typeof DISHTYPES[number][] | undefined) => void;
}) => {
  const { t: tDishType } = useTranslation("dishTypes");
  const { t } = useTranslation("dishes");

  return (
    <MultipleSelect
      options={DISHTYPES.map((d) => ({ value: d, label: tDishType(d) } as const))}
      selected={dishType}
      setSelected={setDishType}
      placeholder={t("Select dish type")}
    />
  );
};

interface ConfirmDeletionModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  dish: DishType;
  refetch: () => {};
}

const ConfirmDeletionModal = ({ open, setOpen, dish, refetch }: ConfirmDeletionModalProps) => {
  const { t } = useTranslation("chef");

  const deleteByAdminWithToken = useAuth(API.dish.deleteByAdmin);

  const { mutate: deleteByAdmin } = useMutation(deleteByAdminWithToken, {
    onSuccess: (res) => {
      setOpen(false);
      refetch();
    },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-8 h-8 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("DeleteDish")}: {dish.name || dish.byChef.name}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t("DeleteExplanation")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => {
            deleteByAdmin({ query: { dishId: dish._id } });
          }}
        >
          {t("Delete")}
        </button>
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

interface UpdateStockModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  dish: DishType;
  refetch: () => {};
}

const UpdateStockModal = ({ open, setOpen, dish, refetch }: UpdateStockModalProps) => {
  const { t } = useTranslation("chef");

  const updateByAdminAuth = useAuth(API.dish.updateByAdmin);

  const { mutate: updateByAdmin } = useMutation(updateByAdminAuth, {
    onSuccess: (res) => {
      setOpen(false);
      refetch();
    },
  });

  const { register, errors, handleSubmit } = useForm(updateByAdmin, {
    resolver: (data, context, options) => {
      const updatedData = { ...data };
      if (updatedData.body.stock) updatedData.body.stock = parseFloat(updatedData.body.stock as any);
      return zodResolver(
        z.object({ body: API.dish.zodSchemas.updateByAdmin.body, query: z.object({ dishId: z.string() }) })
      )(updatedData, context, options);
    },
    defaultValues: { body: { stock: dish.stock }, query: { dishId: dish._id } },
  });

  if (open) console.log("errors", errors);

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit}>
        <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                {t("Update stock of")} {dish.name || dish.byChef.name}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-4 sm:p-6">
          <div className="flex items-end">
            <p className="">{t("Stock")}</p>
          </div>
          <input
            id="stock"
            type="number"
            step="1"
            className="relative block w-full px-3 py-2 mt-2 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Stock")}
            {...register("body.stock")}
            form="mainForm"
          />
        </div>
        <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("Confirm")}
          </button>
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("Cancel")}
          </button>
        </div>
      </form>
    </Overlay>
  );
};
