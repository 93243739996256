import { Fetch } from './fetchBridge'

import { User } from './user';
import { Chef } from './chef';
import { Admin } from './admin';
import { Dish } from './dish';
import { Confirmation } from './confirmation';
import { Order } from './order';
import { Basket } from './basket';
import { Discount } from './discount';
import { OAuth } from './oauth';
import { General } from './general';
import { Statistics } from './statistics';
import { DeviceToken } from './deviceToken';

export const API = {"user":new User(Fetch),"chef":new Chef(Fetch),"admin":new Admin(Fetch),"dish":new Dish(Fetch),"confirmation":new Confirmation(Fetch),"order":new Order(Fetch),"basket":new Basket(Fetch),"discount":new Discount(Fetch),"oauth":new OAuth(Fetch),"general":new General(Fetch),"statistics":new Statistics(Fetch),"deviceToken":new DeviceToken(Fetch)}