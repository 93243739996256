import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import signin_en from "./en/signin.json";
import signup_en from "./en/signup.json";
import resetPassword_en from "./en/resetPassword.json";
import orders_en from "./en/orders.json";
import addADish_en from "./en/addADish.json";
import dish_en from "./en/dish.json";
import dishTypes_en from "./en/dishTypes.json";
import diets_en from "./en/diets.json";
import dishes_en from "./en/dishes.json";
import discounts_en from "./en/discounts.json";
import order_en from "./en/order.json";
import chefs_en from "./en/chefs.json";
import chef_en from "./en/chef.json";
import categories_en from "./en/categories.json";
import allergenes_en from "./en/allergenes.json";
import common_en from "./en/common.json";
import orderStatus_en from "./en/orderStatus.json";

export const resources = {
  en: {
    resetPassword: resetPassword_en,
    signin: signin_en,
    signup: signup_en,
    addADish: addADish_en,
    diets: diets_en,
    dishTypes: dishTypes_en,
    dish: dish_en,
    dishes: dishes_en,
    discounts: discounts_en,
    order: order_en,
    orders: orders_en,
    orderStatus: orderStatus_en,
    chefs: chefs_en,
    chef: chef_en,
    categories: categories_en,
    allergenes: allergenes_en,
    common: common_en,
  },
} as const;

export const defaultNS = "common";

export const checkNamespaces = () => {
  // const t: typeof resources.en = resources.fr;
};

i18n.use(initReactI18next).init({
  lng: "en",
  ns: ["common"],
  resources,
  defaultNS,
  interpolation: { escapeValue: false },
});
