export const getDayOfWeekString = (dayOfWeek: number, locale: string) => {
  const date = new Date();
  var currentDay = date.getDay();
  var distance = dayOfWeek - currentDay;
  date.setDate(date.getDate() + distance);
  return date.toLocaleDateString(locale, { weekday: "long" });
};

export const getNextMonth = (d: Date) => {
  d.setMonth(d.getMonth() + 1);
  return d;
};

export const getPreviousMonth = (d: Date) => {
  d.setMonth(d.getMonth() - 1);
  return d;
};
