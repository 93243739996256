import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type DiscountT = SDKTypes['discount'];

export class Discount {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    create: {
      body: z
  .object({
    name: z.string(),
    type: z.enum(["coupon", "percentage"]),
    amount: z.number().int().gte(0),
    minOrderSize: z.number().int().gte(0),
    tag: z.string().optional(),
  })
  .strict(),
    },
    delete: {
      query: z
  .object({
    _id: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    updateActivity: {
      body: z
  .object({
    active: z.boolean(),
    _id: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    update: {
      body: z
  .object({ active: z.boolean().optional(), tag: z.string().optional() })
  .strict(),
      query: z
  .object({
    _id: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getPageByAdmin: {
      body: z.object({ active: z.boolean().optional() }).strict(),
    },
  };

  public create = (p: { body: DiscountT['create']['body']; headers: DiscountT['create']['headers'] }): Promise<DiscountT['create']['return']> => {
    return this.Fetch({ path: '/discount/create', method: 'POST', ...p });
  };

  public delete = (p: { query: DiscountT['delete']['query']; headers: DiscountT['delete']['headers'] }): Promise<DiscountT['delete']['return']> => {
    return this.Fetch({ path: '/discount/delete', method: 'POST', ...p });
  };

  public updateActivity = (p: { body: DiscountT['updateActivity']['body']; headers: DiscountT['updateActivity']['headers'] }): Promise<DiscountT['updateActivity']['return']> => {
    return this.Fetch({ path: '/discount/updateActivity', method: 'POST', ...p });
  };

  public update = (p: { body: DiscountT['update']['body']; query: DiscountT['update']['query']; headers: DiscountT['update']['headers'] }): Promise<DiscountT['update']['return']> => {
    return this.Fetch({ path: '/discount/update', method: 'POST', ...p });
  };

  public getPageByAdmin = (p: { body: DiscountT['getPageByAdmin']['body']; query: DiscountT['getPageByAdmin']['query']; headers: DiscountT['getPageByAdmin']['headers'] }): Promise<DiscountT['getPageByAdmin']['return']> => {
    return this.Fetch({ path: '/discount/getPageByAdmin', method: 'POST', ...p });
  };
}
