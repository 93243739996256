import { APIType } from "api";
import { useQuery } from "hooks/useBridge";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "sdk";
import { useAuth } from "hooks/useBridge";
import _ from "lodash";
import { Pagination } from "components/utils/Pagination";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { SuccessToast } from "components/utils/Toast";
import { useQueryClient } from "react-query";
import { useMutation } from "hooks/useBridge";
import { ExclamationIcon, TagIcon } from "@heroicons/react/solid";
import { TrashIcon } from "@heroicons/react/outline";
import { Overlay } from "components/utils/Overlay";
import { PlusIcon } from "@radix-ui/react-icons";
import { useForm } from "hooks/useForm";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type DiscountsType = APIType["discount"]["getPageByAdmin"]["NoErrorsReturn"];
type DiscountType = DiscountsType["data"][number];

type GetDiscountsFilters = APIType["discount"]["getPageByAdmin"]["NoHeadersData"];

export const Discounts = () => {
  const { t } = useTranslation("discounts");
  const perPage = 25;
  const [queryFilters, setQueryFilters] = useState<GetDiscountsFilters>({
    body: {},
    query: { skip: "0", limit: perPage.toString() },
  });

  const getDiscounts = useAuth(API.discount.getPageByAdmin);

  const { data: discounts, refetch } = useQuery(["discountsHome", queryFilters], () => getDiscounts(queryFilters), {
    keepPreviousData: true,
  });

  const page = ((queryFilters.query.skip || 0) as number) / perPage;

  const setPage = (p: number) =>
    setQueryFilters((qF) => ({
      ...qF,
      query: {
        ...qF.query,
        skip: (p * perPage).toString(),
        limit: perPage.toString(),
      },
    }));

  return (
    <div className="w-full px-8 mx-auto">
      <div className="flex items-end mt-8">
        <p className="text-4xl font-bold">{t("Discount listing")}</p>
        <p className="ml-4 text-lg text-gray-500">
          {discounts?.total} {t("items")}
        </p>
        <Link
          to="/createDiscount"
          className="relative flex justify-center px-4 py-2 ml-auto text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Add a new discount")}
        </Link>
      </div>
      <div className="p-6 mt-4 bg-white border border-gray-200 rounded-md">
        {discounts && (
          <DiscountsTable
            discounts={discounts}
            refetch={refetch}
            page={page}
            setPage={setPage}
            total={Math.ceil(discounts.total / perPage)}
          />
        )}
      </div>
    </div>
  );
};

const DiscountRow = ({ discount, refetch }: { discount: DiscountType; refetch: () => {} }) => {
  const { t } = useTranslation("discounts");
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdateTag, setOpenUpdateTag] = useState(false);
  const queryClient = useQueryClient();

  const changeStatus = useAuth(API.discount.updateActivity);
  const changeStatusAPI = (value: boolean, id: string) =>
    changeStatus({
      body: {
        _id: id,
        active: value,
      },
    }).then((res) => {
      if ("error" in res) return;
      else {
        queryClient.invalidateQueries("discountsHome");
        toast.custom((toast) => <SuccessToast t={toast} text={"Status updated"} />);
      }
    });

  return (
    <div className="grid w-full grid-cols-12 gap-4 py-2 border-b border-gray-100">
      <div className="flex flex-col col-span-6">
        <div className="flex items-center">
          <p className="text-sm font-bold text-neutral-900">{discount.name}</p>
          {discount.tag ? (
            <div
              className="p-0.5 ml-2 rounded-full bg-main hover:bg-opacity-80 cursor-pointer flex px-1 pr-2 items-center"
              onClick={() => setOpenUpdateTag(true)}
            >
              <TagIcon className="w-3 h-3 text-white" />
              <p className="ml-1 text-white" style={{ fontSize: 10 }}>
                {discount.tag}
              </p>
            </div>
          ) : (
            <div
              className="p-0.5 ml-2 rounded-full bg-main hover:bg-opacity-80 cursor-pointer flex px-1 pr-2 items-center"
              onClick={() => setOpenUpdateTag(true)}
            >
              <PlusIcon className="w-3 h-3 text-white" />
              <p className="ml-1 text-white" style={{ fontSize: 10 }}>
                {t("Add a tag")}
              </p>
            </div>
          )}
        </div>
        <UpdateTagModal open={openUpdateTag} setOpen={setOpenUpdateTag} discount={discount} refetch={refetch} />
        <p className="text-sm text-neutral-600">
          {discount.type === "percentage" ? discount.amount : (discount.amount / 100).toFixed(2)}
          {discount.type === "percentage" ? "%" : "€"} {t("Deducted")} · {t("Minimum order")}:{" "}
          {(discount.minOrderSize / 100).toFixed(2)}€
        </p>
      </div>
      <div className="flex items-center col-span-2 text-sm">
        <p className="text-sm text-gray-900">{new Date(discount.createdAt).toDateString()}</p>
      </div>
      <div className="flex items-center col-span-2 text-sn">
        <div className="hover:bg-gray-100 hover:underline">
          <p className="text-sm text-gray-900">{discount.orders?.[0]?.total || 0}</p>
        </div>
      </div>
      <div className="flex items-center justify-between col-span-2">
        {discount.active === true ? (
          <div
            className="px-4 py-1 text-xs font-bold text-green-600 uppercase bg-green-100 rounded-full cursor-pointer pointer-events-auto hover:scale-105"
            onClick={() => changeStatusAPI(false, discount._id)}
          >
            Active
          </div>
        ) : (
          <div
            className="px-4 py-1 text-xs font-bold text-red-600 uppercase bg-red-100 rounded-full cursor-pointer pointer-events-auto hover:scale-105"
            onClick={() => changeStatusAPI(true, discount._id)}
          >
            Inactive
          </div>
        )}
        <TrashIcon
          className="p-1 text-red-700 border border-gray-200 rounded-md cursor-pointer w-7 h-7 hover:bg-gray-50"
          onClick={() => setOpenDelete(true)}
        />
        <ConfirmDeletionModal open={openDelete} setOpen={setOpenDelete} discount={discount} refetch={refetch} />
      </div>
    </div>
  );
};

export const DiscountsTable = ({
  discounts,
  refetch,
  page,
  setPage,
  total,
}: {
  discounts: DiscountsType;
  refetch: () => {};
  page: number;
  setPage: (page: number) => any;
  total: number;
}) => {
  const { t } = useTranslation("discounts");

  return (
    <div className="">
      <div className="grid grid-cols-12 gap-4 py-2 mt-4 border-b-2 border-gray-100">
        <div className="flex flex-1 col-span-6 ">
          <p className="text-xs font-bold text-gray-600 uppercase">{t("Code")}</p>
        </div>
        <div className="flex items-center col-span-2">
          <p className="text-xs font-bold text-gray-600 uppercase">{t("Creation date")}</p>
        </div>
        <div className="flex col-span-2">
          <p className="text-xs font-bold text-gray-600 uppercase">{t("Usage")}</p>
        </div>
        <div className="flex col-span-2">
          <p className="text-xs font-bold text-gray-600 uppercase">{t("Status")}</p>
        </div>
      </div>
      {discounts.data?.map((discount, i) => (
        <DiscountRow discount={discount} key={discount._id} refetch={refetch} />
      ))}
      <div className="flex items-center justify-center w-full">
        <Pagination page={page} setPage={setPage} total={total} />
      </div>
    </div>
  );
};

interface ConfirmDeletionModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  discount: APIType["discount"]["getPageByAdmin"]["NoErrorsReturn"]["data"][number];
  refetch: () => {};
}

const ConfirmDeletionModal = ({ open, setOpen, discount, refetch }: ConfirmDeletionModalProps) => {
  const { t } = useTranslation("discounts");

  const deleteByAdminWithToken = useAuth(API.discount.delete);

  const { mutate: deleteByAdmin } = useMutation(deleteByAdminWithToken, {
    onSuccess: (res) => {
      setOpen(false);
      refetch();
    },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-8 h-8 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("DeleteDiscount")}: {discount.name}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t("DeleteExplanation")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => {
            deleteByAdmin({ query: { _id: discount?._id } });
          }}
        >
          {t("Delete")}
        </button>
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

interface UpdateTagModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  discount: APIType["discount"]["getPageByAdmin"]["NoErrorsReturn"]["data"][number];
  refetch: () => {};
}

const UpdateTagModal = ({ open, setOpen, discount, refetch }: UpdateTagModalProps) => {
  const { t } = useTranslation("discounts");

  const updateDiscountAuth = useAuth(API.discount.update);
  const { mutate: updateDiscount } = useMutation(updateDiscountAuth, {
    onSuccess: (res) => {
      setOpen(false);
      refetch();
    },
  });

  const { register, errors, handleSubmit } = useForm(updateDiscount, {
    resolver: zodResolver(
      z.object({ body: API.discount.zodSchemas.update.body, query: z.object({ _id: z.string() }) })
    ),
    defaultValues: { body: { tag: discount.tag }, query: { _id: discount?._id } },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit}>
        <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div className="">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                {discount.tag ? t("Update discount") : t("Add discount tag")}: {discount.name}
              </h3>
              <div className="flex items-end">
                <p className="">{t("Tag")}</p>
              </div>
              <input
                id="tag"
                className="relative block w-full px-3 py-2 mt-2 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
                placeholder={t("Tag")}
                {...register("body.tag")}
                form="mainForm"
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("Save")}
          </button>
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("Cancel")}
          </button>
        </div>
      </form>
    </Overlay>
  );
};
