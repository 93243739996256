import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type OAuthT = SDKTypes['oauth'];

export class OAuth {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    apple: {
      body: z
  .object({ language: z.enum(["english", "portuguese"]) })
  .strict(),
      query: z.object({ nonce: z.string(), idtoken: z.string() }).strict(),
    },
    google: {
      body: z
  .object({ language: z.enum(["english", "portuguese"]) })
  .strict(),
      query: z.object({ idtoken: z.string() }).strict(),
    },
    facebook: {
      query: z.object({ accesstoken: z.string() }).strict(),
    },
  };

  public apple = (p: { body: OAuthT['apple']['body']; query: OAuthT['apple']['query']; headers: OAuthT['apple']['headers'] }): Promise<OAuthT['apple']['return']> => {
    return this.Fetch({ path: '/oauth/apple', method: 'POST', ...p });
  };

  public google = (p: { body: OAuthT['google']['body']; headers: OAuthT['google']['headers'] }): Promise<OAuthT['google']['return']> => {
    return this.Fetch({ path: '/oauth/google', method: 'POST', ...p });
  };

  public facebook = (p: { headers: OAuthT['facebook']['headers'] }): Promise<OAuthT['facebook']['return']> => {
    return this.Fetch({ path: '/oauth/facebook', method: 'POST', ...p });
  };
}
