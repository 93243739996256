import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Select = <Option extends { value: string | number; label: string }, Options extends Option[]>({
  options,
  label,
  selected,
  setSelected,
  selectedLabel,
  placeholder,
}: {
  options: Options;
  label?: string;
  selectedLabel?: string;
  selected: Options[number]["value"] | undefined;
  setSelected: (s: Options[number]["value"]) => void;
  placeholder?: string;
}) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
          <div className="relative ">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-9 focus:outline-none focus:ring-1 focus:ring-main focus:border-main sm:text-sm">
              <span className="flex items-center">
                {selected ? (
                  <span className="block truncate">{selectedLabel || selected}</span>
                ) : (
                  <span className="block text-gray-500 truncate">{placeholder}</span>
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map(({ value, label }) => (
                  <Listbox.Option
                    key={value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-main" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}
                          >
                            {label}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-main",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export const MultipleSelect = <Option extends { value: string; label: string }, Options extends Option[]>({
  options,
  label,
  selected,
  setSelected,
  placeholder,
}: {
  options: Options;
  label?: string;
  selected: Options[number]["value"][] | undefined;
  setSelected: (o: Options[number]["value"][] | undefined) => void;
  placeholder?: string;
}) => {
  const toggleSelected = (ns: Options[number]["value"] | undefined) => {
    if (!ns) return;
    setSelected(selected?.includes(ns) ? selected.filter((e) => e !== ns) : [...(selected || []), ns]);
  };

  return (
    <Listbox value={selected?.join(", ")} onChange={toggleSelected}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
          <div className="relative">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-9 focus:outline-none focus:ring-1 focus:ring-main focus:border-main sm:text-sm">
              <span className="flex items-center">
                <span className={`block truncate ${selected && selected.length ? "text-main" : "text-gray-500"}`}>
                  {placeholder}
                </span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map(({ value, label }) => (
                  <Listbox.Option
                    key={value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-main" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={value}
                  >
                    {({ active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected?.includes(value) ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {label}
                          </span>
                        </div>
                        {selected?.includes(value) ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-main",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
