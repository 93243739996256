import z from "zod";
import { XIcon } from "@heroicons/react/outline";
import { Overlay } from "components/utils/Overlay";
import { useMutation, useAuth, useQuery } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "sdk";
import { zodResolver } from "@hookform/resolvers/zod";
import { ALLERGENES } from "data/Allergenes";
import { Control, useController, UseFormRegister } from "react-hook-form";
import { MultipleSelect, Select } from "components/form/Select";
import { CATEGORIES } from "data/Categories";
import { APIType } from "types/api";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { extract } from "utils/Object";
import { DownloadIcon, LoopIcon } from "@radix-ui/react-icons";
import { useGlobalState } from "hooks/useGlobalState";
import { SuccessToast } from "components/utils/Toast";
import { toast } from "react-hot-toast";
import { t } from "i18next";
import { DIETS } from "data/Diets";
import { DISHTYPES } from "data/DishTypes";
import Compress from "react-image-file-resizer";

type UpdateDishDataType = APIType["dish"]["updateByAdmin"]["NoHeadersData"];
type DishType = APIType["dish"]["getByAdmin"]["NoErrorsReturn"];

type Keys = (keyof APIType["dish"]["updateByAdmin"]["Data"]["body"])[];

export const Dish = () => {
  const { id } = useParams();
  const [admin] = useGlobalState("admin");

  const getByAdmin = useAuth(API.dish.getByAdmin);
  const { data: initialDish, isFetchedAfterMount } = useQuery(["dish", id, admin.isAuthenticated], () =>
    getByAdmin({ query: { dishId: id || "" } })
  );

  if (!initialDish || !isFetchedAfterMount) return <div />;
  return <EditDish initialDish={initialDish} />;
};

export const EditDish = ({ initialDish }: { initialDish: DishType }) => {
  const { t } = useTranslation("dish");

  const [addedImage, setAddedImage] = useState<File | null>(null);
  const keys: Keys = [
    "allergens",
    "category",
    "description",
    "diet",
    "name",
    "image",
    "price",
    "ingredients",
    "stock",
    "visibleInApp",
    "type",
    "PLU",
  ];

  const defaultValues = {
    body: { ...extract(initialDish, keys), price: (initialDish?.price || 0) / 100 },
    query: { dishId: initialDish._id || undefined },
  };

  const tokenizedUpdateDish = useAuth(API.dish.updateByAdmin);
  const tokenizedUpdateImagesByAdmin = useAuth(API.dish.updateImageByAdmin);

  const { mutate: updateDish } = useMutation(tokenizedUpdateDish, {
    onSuccess: (res) => {
      reset({
        body: { ...extract(res, keys), price: (res.price || 0) / 100 },
        query: { dishId: res._id },
      });
      toast.custom((toast) => <SuccessToast t={toast} text={t("Successfully modified")} />);
      updateImages({ dishId: res._id });
    },
  });

  const { mutate: updateImagesByChef } = useMutation(tokenizedUpdateImagesByAdmin, {
    onSuccess: (res) => {
      console.log(res);
    },
  });

  const updateImages = ({ dishId }: { dishId: string }) => {
    if (addedImage)
      updateImagesByChef({
        files: { image: addedImage },
        query: { dishId },
      });
  };

  const { register, handleSubmit, errors, control, reset, watch } = useForm(updateDish, {
    defaultValues: defaultValues,
    resolver: (data, context, options) => {
      const updatedData = {
        ...data,
        body: { ...data.body, price: Math.round(parseFloat((data.body.price || 0) as any) * 100) },
      };
      if (updatedData.body.stock) updatedData.body.stock = parseFloat(updatedData.body.stock as any);
      return zodResolver(
        z.object({ body: API.dish.zodSchemas.updateByAdmin.body, query: z.object({ dishId: z.string() }) })
      )(updatedData, context, options);
    },
  });

  console.log(errors);
  console.log(watch());

  useEffect(() => reset(), [initialDish?._id]);

  return (
    <div className="relative w-full max-h-screen ">
      <Header />
      <form className="" onSubmit={handleSubmit} id="mainForm" />
      <div className="grid items-start grid-cols-12 gap-8 px-8 py-4 mx-auto gap-y-2">
        <div className="flex items-end col-span-7">
          <p className="text-2xl font-bold">{t("Dish details")}</p>
        </div>
        <div className="flex items-end col-span-5 text-2xl font-bold">
          <p>{t("Admin options")}</p>
        </div>
        <div className="flex flex-col col-span-7 p-4 bg-white border border-gray-200 rounded-md">
          <NameCard register={register} control={control} initialDish={initialDish} />
          <DishType control={control} initialDish={initialDish} />
          <Category control={control} initialDish={initialDish} />
          <Diet control={control} initialDish={initialDish} />
          <DescriptionCard register={register} control={control} initialDish={initialDish} />
          <IngredientsCard control={control} initialDish={initialDish} />
          <AddAllergenes control={control} initialDish={initialDish} />
        </div>
        <div className="flex flex-col col-span-5 gap-4">
          <div className="flex p-4 bg-white border border-gray-200 rounded-md">
            {initialDish.chef.profileImage && (
              <img src={initialDish.chef.profileImage} className="object-cover w-20 h-20 mr-4 rounded-full" />
            )}
            <div className="flex flex-col justify-center">
              <p className="text-lg font-bold line-clamp-1">
                {initialDish.chef.firstName} {initialDish.chef.lastName}
              </p>
              <a href={`/chefs/${initialDish.chef._id}`} className="text-main hover:underline">
                {t("View profile")}
              </a>
            </div>
          </div>
          <div className="flex flex-col p-4 bg-white border border-gray-200 rounded-md">
            <PLUCard register={register} />
          </div>
          <div className="flex flex-col p-4 bg-white border border-gray-200 rounded-md">
            <VisibilityCard control={control} />
            <StockCard register={register} />
            <PriceCard initialDish={initialDish} register={register} />
          </div>
          <Images addedImage={addedImage} setAddedImage={setAddedImage} control={control} initialDish={initialDish} />
        </div>
      </div>
      <div className=""></div>
    </div>
  );
};

interface IngredientsCardProps {
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const IngredientsCard = ({ control, initialDish }: IngredientsCardProps) => {
  const { t } = useTranslation("dish");

  const [hideIngredients, setHideIngredients] = useState(false);

  const { field } = useController({ name: "body.ingredients", control });
  const { value: ingredients, onChange: setIngredients } = field;

  const submit = ({ ingredient }: { ingredient: string }) => {
    setIngredients([...(ingredients || []), ingredient]);
    reset();
  };

  const { register, handleSubmit, reset } = useForm(submit);

  return (
    <>
      <div className="flex items-end mt-4">
        <p className="">{t("Ingredients")}</p>
        {initialDish.byChef.ingredients?.length ? (
          <LoopIcon
            className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
            onClick={() => setIngredients(initialDish.byChef.ingredients)}
          />
        ) : null}
        <HideShowButton hide={hideIngredients} setHide={setHideIngredients} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mt-2">
          <input
            id="ingredient"
            type="text"
            required
            className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
            placeholder={t("Ingredient")}
            {...register("ingredient")}
          />

          <button
            type="submit"
            className="relative flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
          >
            {t("AddIngredient")}
          </button>
        </div>
        {ingredients && ingredients?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {ingredients?.map((ingredient, i) => (
              <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50" key={i}>
                <p className="flex text-sm text-gray-700">{ingredient}</p>
                <XIcon
                  className="w-4 p-0.5 hover:bg-gray-100 ml-1 cursor-pointer"
                  onClick={() => setIngredients(ingredients.filter((_, j) => i !== j))}
                />
              </div>
            ))}
          </div>
        )}
      </form>
      {!hideIngredients && initialDish.byChef.ingredients?.length ? (
        <div className="mt-2 border-l-4 border-main" style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
          <div className="flex flex-col items-start py-2 pl-4 border-t border-b border-r border-gray-200 rounded-r-md">
            <p className="mb-2 text-sm font-semibold">{t("Chef's input")}</p>
            <div className="flex flex-wrap gap-2">
              {initialDish.byChef.ingredients?.map((ingredient, i) => (
                <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50" key={i}>
                  <p className="flex text-sm text-gray-700">{ingredient}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

interface NameCardProps {
  register: UseFormRegister<UpdateDishDataType>;
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const NameCard = ({ register, control, initialDish }: NameCardProps) => {
  const { t } = useTranslation("dish");

  const [hideName, setHideName] = useState(false);

  const { field } = useController({ name: "body.name", control });
  const { value: name, onChange: setName } = field;

  return (
    <>
      <div className="flex items-end">
        <p className="">{t("Name")}</p>
        <LoopIcon
          className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
          onClick={() => setName(initialDish.byChef.name)}
        />
        <HideShowButton hide={hideName} setHide={setHideName} />
      </div>
      <input
        id="name"
        className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
        placeholder={t("Name")}
        {...register("body.name")}
        value={name}
        form="mainForm"
      />
      {!hideName && (
        <div className="mt-4 border-l-4 border-main" style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
          <div className="py-2 pl-4 border-t border-b border-r border-gray-200 rounded-r-md">
            <p className="text-sm font-semibold">{t("Chef's input")}</p>
            <p className="text-sm text-gray-500">{initialDish.byChef.name}</p>
          </div>
        </div>
      )}
    </>
  );
};
interface PLUCardProps {
  register: UseFormRegister<UpdateDishDataType>;
}

const PLUCard = ({ register }: PLUCardProps) => {
  const { t } = useTranslation("dish");

  return (
    <>
      <div className="flex items-end">
        <p className="">{t("PLU")}</p>
      </div>
      <input
        id="plu"
        className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
        placeholder={t("ex: P-CO-ovIo-175")}
        {...register("body.PLU")}
        form="mainForm"
      />
    </>
  );
};

interface StockCardProps {
  register: UseFormRegister<UpdateDishDataType>;
}

const StockCard = ({ register }: StockCardProps) => {
  const { t } = useTranslation("dish");

  return (
    <>
      <div className="flex items-end">
        <p className="">{t("Stock")}</p>
      </div>

      <input
        id="stock"
        type="number"
        step="1"
        className="relative block w-full px-3 py-2 mt-2 mb-4 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
        placeholder={t("Stock")}
        {...register("body.stock")}
        form="mainForm"
      />
    </>
  );
};
interface PriceCardProps {
  register: UseFormRegister<UpdateDishDataType>;
  initialDish: DishType;
}

const PriceCard = ({ register, initialDish }: PriceCardProps) => {
  const { t } = useTranslation("dish");

  return (
    <>
      <div className="flex items-end">
        <p className="">{t("Price")}</p>
      </div>
      <input
        id="price"
        type="number"
        step="0.01"
        min={0}
        className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
        placeholder={t("Price")}
        {...register("body.price")}
        form="mainForm"
      />
      {initialDish.byChef.price && (
        <p className="self-end mt-1 text-sm">
          {t("Price asked by chef")}: <span className="text-main">{(initialDish.byChef.price / 100).toFixed(2)}</span>
        </p>
      )}
    </>
  );
};
interface VisibilityCardProps {
  control: Control<UpdateDishDataType>;
}

const VisibilityCard = ({ control }: VisibilityCardProps) => {
  const { t } = useTranslation("dish");

  const { field } = useController({ name: "body.visibleInApp", control });
  const { value: visibleInApp, onChange: setVisibleInApp } = field;

  return (
    <>
      <div className="flex items-end">
        <label htmlFor="visibility" className="font-medium text-gray-700">
          Visibility
        </label>
      </div>

      <div className="flex items-start mt-2 mb-2 cursor-pointer">
        <div className="flex items-center h-5">
          <input
            id="visibility"
            type="checkbox"
            className="w-4 h-4 border-gray-300 rounded cursor-pointer text-main focus:ring-main"
            defaultChecked={visibleInApp}
            onChange={(e) => {
              setVisibleInApp(!visibleInApp);
            }}
          />
        </div>
        <label htmlFor="visibility" className="cursor-pointer">
          <div className="ml-3 text-sm">
            <p className="">{t("Show the product")}</p>
          </div>
        </label>
      </div>
    </>
  );
};

interface CategoryProps {
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const Category = ({ control, initialDish }: CategoryProps) => {
  const { t } = useTranslation("dish");
  const { field } = useController({ name: "body.category", control });
  const { onChange: setCategory } = field;

  return (
    <>
      <div className="flex items-end mt-4">
        <p className="">{t("Category")}</p>
        <LoopIcon
          className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
          onClick={() => setCategory(initialDish.byChef.category)}
        />
        {initialDish.byChef.category && (
          <p className="self-end mt-1 ml-auto text-sm">
            {t("Chef's category")}: <span className="text-main">{initialDish.byChef.category}</span>
          </p>
        )}
      </div>
      <CategorySelect control={control} />
    </>
  );
};

interface DishTypeProps {
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const DishType = ({ control, initialDish }: DishTypeProps) => {
  const { t } = useTranslation("dish");
  const { field } = useController({ name: "body.type", control });
  const { onChange: setDishType } = field;

  return (
    <>
      <div className="flex items-end mt-4">
        <p className="">{t("DishType")}</p>
        {initialDish.byChef.type ? (
          <LoopIcon
            className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
            onClick={() => setDishType(initialDish.byChef.type)}
          />
        ) : null}
        {initialDish.byChef.type && (
          <p className="self-end mt-1 ml-auto text-sm">
            {t("Chef's category")}: <span className="text-main">{initialDish.byChef.type}</span>
          </p>
        )}
      </div>
      <DishTypeSelect control={control} />
    </>
  );
};

interface DietProps {
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const Diet = ({ control, initialDish }: DietProps) => {
  const { t } = useTranslation("dish");

  const { field } = useController({ name: "body.diet", control });
  const { value: diet, onChange: setDiet } = field;

  return (
    <>
      <div className="flex items-end mt-4 mb-2">
        <p className="">{t("Diet")}</p>{" "}
        {initialDish.byChef.diet?.length ? (
          <LoopIcon
            className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
            onClick={() => setDiet(initialDish.byChef.diet)}
          />
        ) : null}
        {initialDish.byChef.diet && (
          <p className="self-end mt-1 ml-auto text-sm">
            {t("Chef's diet")}: <span className="text-main">{initialDish.byChef.diet.join(", ") || t("No diets")}</span>
          </p>
        )}
      </div>
      <DietSelect control={control} />
      <div className="flex flex-wrap gap-2 mt-2">
        {diet?.map((d) => (
          <div className="flex items-center px-2 py-1 pr-1 border border-gray-200 rounded-md bg-gray-50">
            <p className="text-sm ">{d}</p>
            <XIcon
              className="w-6 h-6 p-1 ml-0.5 cursor-pointer hover:bg-gray-"
              onClick={() => setDiet(diet?.filter((d2) => d2 !== d))}
            />
          </div>
        ))}
      </div>
    </>
  );
};

interface DescriptionCardProps {
  register: UseFormRegister<UpdateDishDataType>;
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const DescriptionCard = ({ register, control, initialDish }: DescriptionCardProps) => {
  const { t } = useTranslation("dish");

  const [hideDescription, setHideDescription] = useState(false);

  const { field } = useController({ name: "body.description", control });
  const { value: description, onChange: setDescription } = field;

  return (
    <>
      <div className="flex items-end mt-4">
        <p className="">{t("DescriptionDish")}</p>
        {initialDish.byChef.description ? (
          <LoopIcon
            className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
            onClick={() => setDescription(initialDish.byChef.description)}
          />
        ) : null}
        <HideShowButton hide={hideDescription} setHide={setHideDescription} />
      </div>
      <textarea
        id="description"
        rows={6}
        className="block w-full mt-2 border border-gray-300 rounded-md focus:ring-main focus:border-main sm:text-sm"
        placeholder={t("DescriptionDish")}
        {...register("body.description")}
        value={description}
        form="mainForm"
      />
      {!hideDescription && (
        <div className="mt-4 border-l-4 border-main" style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
          <div className="py-2 pl-4 border-t border-b border-r border-gray-200 rounded-r-md">
            <p className="text-sm font-semibold">{t("Chef's input")}</p>
            <p className="pr-4 text-sm text-gray-500">{initialDish.byChef.description}</p>
          </div>
        </div>
      )}
    </>
  );
};

interface ImagesProps {
  addedImage: File | null;
  setAddedImage: Dispatch<SetStateAction<File | null>>;
  control: Control<UpdateDishDataType>;
  initialDish: DishType;
}

const Images = ({ addedImage, setAddedImage, initialDish, control }: ImagesProps) => {
  const { t } = useTranslation("dish");

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const im = e.target.files?.[0];
    if (im && addedImage?.name !== im.name && addedImage?.lastModified !== im.lastModified) {
      //@ts-ignore
      Compress.imageFileResizer(im, 800, 450, "JPEG", 80, 0, setAddedImage, "file");
    }
  };

  const { field } = useController({ name: "body.image", control });
  const { value: image, onChange: setImage } = field;

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-md">
      <div className="flex mb-4">
        <p className="font-semibold">{t("Image on the app")}</p>
        {(image || addedImage) && (
          <button
            type="button"
            onClick={() => (image ? setImage("") : setAddedImage(null))}
            className="inline-flex justify-center w-full px-2 py-1 ml-auto text-sm text-white bg-red-500 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
          >
            {t("Delete image")}
          </button>
        )}
      </div>

      {image ? (
        <div className="relative">
          <img src={image} className="flex flex-1 object-cover w-full rounded-md" style={{ aspectRatio: "1" }} />
        </div>
      ) : addedImage ? (
        <div className="relative">
          <img
            src={URL.createObjectURL(addedImage)}
            className="flex flex-1 object-cover w-full rounded-md"
            style={{ aspectRatio: "1" }}
          />
        </div>
      ) : (
        <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg
              className="w-12 h-12 mx-auto text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex items-center text-sm text-center text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative items-center w-full font-medium text-center bg-white rounded-md cursor-pointer text-main hover:text-main focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-main"
              >
                <span>{t("Upload files")}</span>
              </label>
              <input
                id="file-upload"
                accept=".png,.jpg,.jpeg,.png,.gif"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={uploadFile}
              />
            </div>
            <p className="text-xs text-gray-500">{t("PNG, JPG, GIF up to 10MB")}</p>
          </div>
        </div>
      )}
      <div className="flex flex-wrap gap-4 mt-4">
        {initialDish.byChef.images?.map((i) => (
          <ImageSelect image={i} />
        ))}
      </div>
    </div>
  );
};

const ImageSelect = ({ image }: { image: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative">
      <img src={image} className="object-cover w-24 h-24 rounded-md cursor-pointer" onClick={() => setOpen(true)} />
      <Overlay open={open} setOpen={setOpen}>
        <a
          className="absolute flex items-center px-3 py-2 text-white border border-white rounded-md cursor-pointer top-4 right-4 bg-main"
          download
          href={image}
        >
          <p className="">{t("Download")}</p>
          <DownloadIcon className="w-4 h-4 ml-2" />
        </a>
        <img src={image} className="object-cover w-full" style={{ aspectRatio: "1" }} />
      </Overlay>
    </div>
  );
};

const CategorySelect = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t: tCategory } = useTranslation("categories");
  const { t } = useTranslation("dish");
  const { field } = useController({ name: "body.category", control });
  const { value: category, onChange: setCategory } = field;

  return (
    <Select
      options={CATEGORIES.map((c) => ({ value: c, label: tCategory(c) } as const))}
      selected={category}
      setSelected={setCategory}
      placeholder={t("Select a category")}
    />
  );
};

const DishTypeSelect = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t: tDishType } = useTranslation("dishTypes");
  const { t } = useTranslation("dish");
  const { field } = useController({ name: "body.type", control });
  const { value: type, onChange: setType } = field;

  return (
    <Select
      options={DISHTYPES.map((d) => ({ value: d, label: tDishType(d) } as const))}
      selected={type}
      setSelected={setType}
      placeholder={t("Select a category")}
    />
  );
};

const DietSelect = ({ control }: { control: Control<UpdateDishDataType> }) => {
  const { t: tDiet } = useTranslation("diets");
  const { t } = useTranslation("dish");
  const { field } = useController({ name: "body.diet", control });
  const { value: diet, onChange: setDiet } = field;

  return (
    <MultipleSelect
      options={DIETS.map((d) => ({ value: d, label: tDiet(d) } as const))}
      selected={diet}
      setSelected={setDiet}
      placeholder={t("Select diets")}
    />
  );
};

interface AllergenesModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  allergenes: typeof ALLERGENES[number][];
  setAllergenes: (a: typeof ALLERGENES[number][]) => void;
}

const AllergenesModal = ({ open, setOpen, allergenes, setAllergenes }: AllergenesModalProps) => {
  const { t } = useTranslation("dish");
  const [localAllergenes, setLocalAllergenes] = useState<typeof ALLERGENES[number][]>([]);

  const toggleAllergenes = (allergene: typeof ALLERGENES[number]) =>
    !localAllergenes.includes(allergene)
      ? setLocalAllergenes([allergene, ...localAllergenes])
      : setLocalAllergenes(localAllergenes.filter((a) => a !== allergene));

  useEffect(() => {
    setLocalAllergenes(allergenes);
  }, [allergenes]);

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="w-full">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
              {t("Select the allergenes")}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t("SelectAllergenesExplanation")}</p>
            </div>
            <div className="grid grid-cols-2 mt-4">
              {ALLERGENES.map((allergene, i) => (
                <div
                  className="flex items-center col-span-1 cursor-pointer"
                  onClick={() => toggleAllergenes(allergene)}
                  key={i}
                >
                  <input
                    id="check"
                    readOnly={true}
                    name="check"
                    checked={localAllergenes.includes(allergene)}
                    type="checkbox"
                    className="w-4 h-4 border-gray-300 rounded text-main focus:ring-main"
                  />
                  <p className="ml-3">{allergene}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <form
          id="allegenesForm"
          onSubmit={(e) => {
            e.preventDefault();
            setAllergenes(localAllergenes);
            setOpen(false);
          }}
        />
        <button
          type="submit"
          form="allegenesForm"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            setAllergenes(allergenes);
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

const AddAllergenes = ({ control, initialDish }: { control: Control<UpdateDishDataType>; initialDish: DishType }) => {
  const [openAllergenes, setOpenAllergenes] = useState(false);
  const { t } = useTranslation("dish");

  const { field } = useController({ name: "body.allergens", control });
  const { value: allergenes, onChange: setAllergenes } = field;

  return (
    <div className="mb-8">
      <AllergenesModal
        open={openAllergenes}
        setOpen={setOpenAllergenes}
        allergenes={allergenes || []}
        setAllergenes={setAllergenes}
      />
      <div className="flex items-center mt-4">
        <p className="">{t("Allergenes")}</p>
        {initialDish.byChef.allergens?.length ? (
          <LoopIcon
            className="w-6 h-6 p-1 ml-2 rounded-md cursor-pointer hover:bg-gray-50 text-main"
            onClick={() => {
              setAllergenes(initialDish.byChef.allergens);
              console.log(initialDish.byChef.allergens);
            }}
          />
        ) : null}
        <button
          type="button"
          onClick={() => setOpenAllergenes(true)}
          className="inline-flex justify-center w-full px-4 py-2 ml-auto text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:w-auto sm:text-sm"
        >
          {t("Select")}
        </button>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {allergenes?.map((allergene, i) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50" key={allergene}>
            <p className="flex text-sm text-gray-700">{allergene}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation("dish");
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-20 flex items-center w-full h-16 px-8 bg-white border-b-2 border-gray-200">
      <div className="p-1 border rounded-md cursor-pointer" onClick={() => navigate(-1)}>
        <ArrowLeftIcon className="w-6 h-6 text-gray-500" />
      </div>
      <div className="flex items-center ml-auto">
        <Link
          to="/dishes"
          className="relative flex justify-center px-4 py-2 ml-auto text-sm font-medium border border-gray-500 rounded-md text-main group hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Cancel")}
        </Link>
        <button
          type="submit"
          form="mainForm"
          className="relative flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
        >
          {t("Save")}
        </button>
      </div>
    </div>
  );
};

const HideShowButton = ({ hide, setHide }: { hide: boolean; setHide: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation("dish");
  return (
    <p
      className="py-1 px-1.5 rounded-md ml-auto text-sm cursor-pointer hover:bg-gray-50 text-main "
      onClick={() => setHide(!hide)}
    >
      {hide ? t("Show chef's input") : t("Hide")}
    </p>
  );
};
