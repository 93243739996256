export const ChangePassword = () => <div />;
// import { LockClosedIcon } from "@heroicons/react/solid";
// import { useTranslation } from "react-i18next";

// export const ChangePassword = () => {
//   const { t } = useTranslation("auth");

//   return (
//     <>
//       <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
//         <div className="w-full max-w-md space-y-8">
//           <div>
//             <img
//               className="w-auto h-12 mx-auto"
//               src="https://tailwindui.com/img/logos/workflow-mark-main.svg"
//               alt="Workflow"
//             />
//             <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">{t("Change password")}</h2>
//           </div>
//           <form className="mt-8 space-y-6" action="#" method="POST">
//             <input type="hidden" name="remember" defaultValue="true" />
//             <div className="-space-y-px rounded-md">
//               <div>
//                 <label htmlFor="email-address" className="sr-only">
//                   {t("New password")}
//                 </label>
//                 <input
//                   id="email-address"
//                   name="email"
//                   type="email"
//                   autoComplete="email"
//                   required
//                   className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
//                   placeholder={t("New password")}
//                 />
//               </div>
//               <div>
//                 <label htmlFor="password" className="sr-only">
//                   {t("Confirm new password")}
//                 </label>
//                 <input
//                   id="password"
//                   name="password"
//                   type="password"
//                   autoComplete="current-password"
//                   required
//                   className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-main focus:border-main focus:z-10 sm:text-sm"
//                   placeholder={t("Confirm new password")}
//                 />
//               </div>
//             </div>

//             <div>
//               <button
//                 type="submit"
//                 className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-main group hover:bg-mainDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main"
//               >
//                 <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                   <LockClosedIcon className="w-5 h-5 text-main group-hover:text-mainLight" aria-hidden="true" />
//                 </span>
//                 {t("Confirm change")}
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };
