import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type DishT = SDKTypes['dish'];

export class Dish {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    create: {
      body: z
  .object({
    name: z.string(),
    category: z.enum([
      "African",
      "Asian",
      "Central-American",
      "European",
      "Frozen",
      "Middle-Eastern",
      "Snacks",
      "South-American",
      "South-Asian",
      "Sweets",
      "Others",
    ]),
    description: z.string().optional(),
    images: z.array(z.string()).optional(),
    price: z.number().int().gte(0).optional(),
    allergens: z
      .array(
        z.enum([
          "Cereals containing gluten",
          "Crustaceans",
          "Eggs",
          "Fish",
          "Peanuts",
          "Soybeans",
          "Milk",
          "Nuts",
          "Celery",
          "Mustard",
          "Sesame seeds",
          "Sulphur dioxide and sulphites",
          "Lupin",
          "Molluscs",
        ])
      )
      .optional(),
    ingredients: z.array(z.string()).optional(),
    diet: z
      .array(
        z.enum(["Halal", "Vegan", "Vegetarian", "Gluten free", "Dairy Free"])
      )
      .optional(),
    type: z
      .enum(["Starter", "Main", "Dessert", "Snack", "Drinks", "Side"])
      .optional(),
  })
  .strict(),
    },
    getByChef: {
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getByAdmin: {
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getPageByAdmin: {
      body: z
  .object({
    visibleInApp: z.boolean().optional(),
    chefName: z.string().optional(),
    textSearch: z.string().optional(),
    category: z
      .array(
        z.enum([
          "African",
          "Asian",
          "Central-American",
          "European",
          "Frozen",
          "Middle-Eastern",
          "Snacks",
          "South-American",
          "South-Asian",
          "Sweets",
          "Others",
        ])
      )
      .optional(),
    diet: z
      .array(
        z.enum(["Halal", "Vegan", "Vegetarian", "Gluten free", "Dairy Free"])
      )
      .optional(),
    dishType: z
      .array(z.enum(["Starter", "Main", "Dessert", "Snack", "Drinks", "Side"]))
      .optional(),
    withoutAllergens: z
      .array(
        z.enum([
          "Cereals containing gluten",
          "Crustaceans",
          "Eggs",
          "Fish",
          "Peanuts",
          "Soybeans",
          "Milk",
          "Nuts",
          "Celery",
          "Mustard",
          "Sesame seeds",
          "Sulphur dioxide and sulphites",
          "Lupin",
          "Molluscs",
        ])
      )
      .optional(),
  })
  .strict(),
      query: z
  .object({
    sortOnStock: z.enum(["-1", "1"]).optional(),
    sortOnChefPosition: z.enum(["-1", "1"]).optional(),
  })
  .strict(),
    },
    updateByChef: {
      body: z
  .object({
    name: z.string(),
    category: z.enum([
      "African",
      "Asian",
      "Central-American",
      "European",
      "Frozen",
      "Middle-Eastern",
      "Snacks",
      "South-American",
      "South-Asian",
      "Sweets",
      "Others",
    ]),
    description: z.string().optional(),
    images: z.array(z.string()).optional(),
    price: z.number().int().gte(0).optional(),
    allergens: z
      .array(
        z.enum([
          "Cereals containing gluten",
          "Crustaceans",
          "Eggs",
          "Fish",
          "Peanuts",
          "Soybeans",
          "Milk",
          "Nuts",
          "Celery",
          "Mustard",
          "Sesame seeds",
          "Sulphur dioxide and sulphites",
          "Lupin",
          "Molluscs",
        ])
      )
      .optional(),
    ingredients: z.array(z.string()).optional(),
    diet: z
      .array(
        z.enum(["Halal", "Vegan", "Vegetarian", "Gluten free", "Dairy Free"])
      )
      .optional(),
    type: z
      .enum(["Starter", "Main", "Dessert", "Snack", "Drinks", "Side"])
      .optional(),
  })
  .strict(),
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    updateByAdmin: {
      body: z
  .object({
    visibleInApp: z.boolean().optional(),
    stock: z.number().int().gte(0).optional(),
    name: z.string().optional(),
    description: z.string().optional(),
    image: z.string().optional(),
    price: z.number().int().gte(0).optional(),
    allergens: z
      .array(
        z.enum([
          "Cereals containing gluten",
          "Crustaceans",
          "Eggs",
          "Fish",
          "Peanuts",
          "Soybeans",
          "Milk",
          "Nuts",
          "Celery",
          "Mustard",
          "Sesame seeds",
          "Sulphur dioxide and sulphites",
          "Lupin",
          "Molluscs",
        ])
      )
      .optional(),
    ingredients: z.array(z.string()).optional(),
    category: z
      .enum([
        "African",
        "Asian",
        "Central-American",
        "European",
        "Frozen",
        "Middle-Eastern",
        "Snacks",
        "South-American",
        "South-Asian",
        "Sweets",
        "Others",
      ])
      .optional(),
    diet: z
      .array(
        z.enum(["Halal", "Vegan", "Vegetarian", "Gluten free", "Dairy Free"])
      )
      .optional(),
    type: z
      .enum(["Starter", "Main", "Dessert", "Snack", "Drinks", "Side"])
      .optional(),
    positionVisibility: z.number().int().gte(0).optional(),
    byChef: z
      .object({
        name: z.string(),
        category: z.enum([
          "African",
          "Asian",
          "Central-American",
          "European",
          "Frozen",
          "Middle-Eastern",
          "Snacks",
          "South-American",
          "South-Asian",
          "Sweets",
          "Others",
        ]),
        description: z.string().optional(),
        images: z.array(z.string()).optional(),
        price: z.number().int().gte(0).optional(),
        allergens: z
          .array(
            z.enum([
              "Cereals containing gluten",
              "Crustaceans",
              "Eggs",
              "Fish",
              "Peanuts",
              "Soybeans",
              "Milk",
              "Nuts",
              "Celery",
              "Mustard",
              "Sesame seeds",
              "Sulphur dioxide and sulphites",
              "Lupin",
              "Molluscs",
            ])
          )
          .optional(),
        ingredients: z.array(z.string()).optional(),
        diet: z
          .array(
            z.enum([
              "Halal",
              "Vegan",
              "Vegetarian",
              "Gluten free",
              "Dairy Free",
            ])
          )
          .optional(),
        type: z.any().optional(),
      })
      .strict()
      .optional(),
    PLU: z.string().optional(),
  })
  .strict(),
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    updateImagesByChef: {
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    updateImageByAdmin: {
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    deleteByAdmin: {
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    deleteByChef: {
      query: z
  .object({
    dishId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
  };

  public create = (p: { body: DishT['create']['body']; headers: DishT['create']['headers'] }): Promise<DishT['create']['return']> => {
    return this.Fetch({ path: '/dish/create', method: 'POST', ...p });
  };

  public getByChef = (p: { query: DishT['getByChef']['query']; headers: DishT['getByChef']['headers'] }): Promise<DishT['getByChef']['return']> => {
    return this.Fetch({ path: '/dish/getByChef', method: 'POST', ...p });
  };

  public getByAdmin = (p: { query: DishT['getByAdmin']['query']; headers: DishT['getByAdmin']['headers'] }): Promise<DishT['getByAdmin']['return']> => {
    return this.Fetch({ path: '/dish/getByAdmin', method: 'POST', ...p });
  };

  public getActiveCategories = (): Promise<DishT['getActiveCategories']['return']> => {
    return this.Fetch({ path: '/dish/getActiveCategories', method: 'POST'});
  };

  /** The stock option in the body is the minimum stock to search on.*/
  public getPageByAdmin = (p: { body: DishT['getPageByAdmin']['body']; query: DishT['getPageByAdmin']['query']; headers: DishT['getPageByAdmin']['headers'] }): Promise<DishT['getPageByAdmin']['return']> => {
    return this.Fetch({ path: '/dish/getPageByAdmin', method: 'POST', ...p });
  };

  public updateByChef = (p: { body: DishT['updateByChef']['body']; query: DishT['updateByChef']['query']; headers: DishT['updateByChef']['headers'] }): Promise<DishT['updateByChef']['return']> => {
    return this.Fetch({ path: '/dish/updateByChef', method: 'POST', ...p });
  };

  public updateByAdmin = (p: { body: DishT['updateByAdmin']['body']; query: DishT['updateByAdmin']['query']; headers: DishT['updateByAdmin']['headers'] }): Promise<DishT['updateByAdmin']['return']> => {
    return this.Fetch({ path: '/dish/updateByAdmin', method: 'POST', ...p });
  };

  public updateImagesByChef = (p: { query: DishT['updateImagesByChef']['query']; headers: DishT['updateImagesByChef']['headers']; files: Record<string, File> }): Promise<DishT['updateImagesByChef']['return']> => {
    return this.Fetch({ path: '/dish/updateImagesByChef', method: 'POST', ...p });
  };

  public updateImageByAdmin = (p: { query: DishT['updateImageByAdmin']['query']; headers: DishT['updateImageByAdmin']['headers']; files: { image: File };  }): Promise<DishT['updateImageByAdmin']['return']> => {
    return this.Fetch({ path: '/dish/updateImageByAdmin', method: 'POST', ...p });
  };

  public deleteByAdmin = (p: { query: DishT['deleteByAdmin']['query']; headers: DishT['deleteByAdmin']['headers'] }): Promise<DishT['deleteByAdmin']['return']> => {
    return this.Fetch({ path: '/dish/deleteByAdmin', method: 'POST', ...p });
  };

  public deleteByChef = (p: { query: DishT['deleteByChef']['query']; headers: DishT['deleteByChef']['headers'] }): Promise<DishT['deleteByChef']['return']> => {
    return this.Fetch({ path: '/dish/deleteByChef', method: 'POST', ...p });
  };
}
