import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChangePassword } from "./auth/ChangePassword";
import { Dashboard } from "./dashboard";
import { Dish } from "./dashboard/Dish";
import { Dishes } from "./dashboard/Dishes";
import { ResetPassword } from "./auth/ResetPassword";
import { Signin } from "./auth/Signin";
import { Orders } from "./dashboard/Orders";
import { Order } from "./dashboard/Order";
import { Chefs } from "./dashboard/Chefs";
import { Chef } from "./dashboard/Chef";
import { useLocalStorageTokens } from "hooks/useLocalStorage";
import { Discounts } from "./dashboard/Discounts";
import { CreateDiscount } from "./dashboard/CreateDiscount";
import { Home } from "./dashboard/Home";

export const Router = () => {
  useLocalStorageTokens();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route path="/" element={<Home />} />
          <Route path="dishes" element={<Dishes />} />
          <Route path="dishes/:id" element={<Dish />} />
          <Route path="chefs" element={<Chefs />} />
          <Route path="chefs/:id" element={<Chef />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:id" element={<Order />} />
          <Route path="discounts" element={<Discounts />} />
          <Route path="createDiscount" element={<CreateDiscount />} />
        </Route>
        {/* Auth */}
        <Route path="/signin" element={<Signin />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/change_password" element={<ChangePassword />} />
      </Routes>
    </BrowserRouter>
  );
};
