import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "hooks/useGlobalState";
import { useEffect } from "react";

export const useRedirectIfNotAuthenticated = () => {
  const [admin] = useGlobalState("admin");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (admin.isAuthenticated === false) navigate(`/signin?from=${location.pathname}`);
  }, [admin.isAuthenticated]);
};
