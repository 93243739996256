import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type StatisticsT = SDKTypes['statistics'];

export class Statistics {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    numberOfNewUsers: {
      body: z
  .object({ withPhoneValidated: z.boolean().optional() })
  .strict(),
    },
  };

  public numberOfNewUsers = (p: { body: StatisticsT['numberOfNewUsers']['body']; headers: StatisticsT['numberOfNewUsers']['headers'] }): Promise<StatisticsT['numberOfNewUsers']['return']> => {
    return this.Fetch({ path: '/statistics/numberOfNewUsers', method: 'POST', ...p });
  };

  public numberOActiveUsers = (p: { body: StatisticsT['numberOActiveUsers']['body']; headers: StatisticsT['numberOActiveUsers']['headers'] }): Promise<StatisticsT['numberOActiveUsers']['return']> => {
    return this.Fetch({ path: '/statistics/numberOActiveUsers', method: 'POST', ...p });
  };

  public numberOfPaidOrders = (p: { body: StatisticsT['numberOfPaidOrders']['body']; headers: StatisticsT['numberOfPaidOrders']['headers'] }): Promise<StatisticsT['numberOfPaidOrders']['return']> => {
    return this.Fetch({ path: '/statistics/numberOfPaidOrders', method: 'POST', ...p });
  };

  public revenue = (p: { body: StatisticsT['revenue']['body']; headers: StatisticsT['revenue']['headers'] }): Promise<StatisticsT['revenue']['return']> => {
    return this.Fetch({ path: '/statistics/revenue', method: 'POST', ...p });
  };

  public numberOfVisibleDishes = (p: { headers: StatisticsT['numberOfVisibleDishes']['headers'] }): Promise<StatisticsT['numberOfVisibleDishes']['return']> => {
    return this.Fetch({ path: '/statistics/numberOfVisibleDishes', method: 'POST', ...p });
  };
}
