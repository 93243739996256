import { CollectionIcon, HomeIcon, LogoutIcon, TicketIcon, UserIcon } from "@heroicons/react/solid";
import { useRedirectIfNotAuthenticated } from "hooks/useSession";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import logo from "assets/logo_negative.png";
import { useGlobalState } from "hooks/useGlobalState";
import { API } from "sdk";
import { useAuth, useMutation } from "hooks/useBridge";
import { removeTokens } from "hooks/useLocalStorage";
import { useState } from "react";
import { useQuery } from "react-query";
import { Overlay } from "components/utils/Overlay";

export const Dashboard = () => {
  useRedirectIfNotAuthenticated();
  const [admin] = useGlobalState("admin");
  return (
    <div className="flex flex-row h-screen" style={{ minWidth: 1300 }}>
      <SideBar />
      <div className="flex flex-col flex-1 pb-16 overflow-scroll bg-gray-50">{admin.isAuthenticated && <Outlet />}</div>
    </div>
  );
};

const SideBar = () => {
  const { t } = useTranslation("common");
  const [admin, setAdmin] = useGlobalState("admin");
  const { mutate: logout } = useMutation(API.admin.logout, {
    onSuccess: () => {
      setAdmin({
        isAuthenticated: false,
        refreshToken: null,
        accessToken: null,
      });
      removeTokens();
    },
    onError: () => {
      setAdmin({
        isAuthenticated: false,
        refreshToken: null,
        accessToken: null,
      });
      removeTokens();
    },
  });

  return (
    <div className="flex flex-col w-64 border-r-2 border-gray-200 bg-main">
      <StoreState />
      <div className="flex flex-col items-center w-full">
        <img src={logo} className="w-32 p-4" />
      </div>
      <p className="px-4 text-xs font-bold text-white uppercase text-opacity-70">Navigation</p>
      <div className="flex flex-col flex-1 mt-2 text-white">
        <NavLink
          to="/"
          className={({ isActive }) =>
            "flex mx-3 rounded-md " +
            (!isActive
              ? "border-transparent hover:bg-opacity-20 hover:bg-mainDark"
              : "bg-mainDark border-mainLight font-medium")
          }
        >
          <HomeIcon className="w-5 ml-3" />
          <p className="p-2 pl-3 font-medium">{t("Home")}</p>
        </NavLink>
        <NavLink
          to="/chefs"
          className={({ isActive }) =>
            "flex mx-3 rounded-md " +
            (!isActive
              ? "border-transparent hover:bg-opacity-20 hover:bg-mainDark"
              : "bg-mainDark border-mainLight font-medium")
          }
        >
          <UserIcon className="w-5 ml-3" />
          <p className="p-2 pl-3 font-medium">{t("Chefs")}</p>
        </NavLink>
        <NavLink
          to="/dishes"
          className={({ isActive }) =>
            "flex mx-3 rounded-md " +
            (!isActive
              ? "border-transparent hover:bg-opacity-20 hover:bg-mainDark"
              : "bg-mainDark border-mainLight font-medium")
          }
        >
          <CollectionIcon className="w-5 ml-3" />
          <p className="p-2 pl-3 font-medium">{t("Dishes")}</p>
        </NavLink>
        <NavLink
          to="/orders"
          className={({ isActive }) =>
            "flex mx-3 rounded-md " +
            (!isActive
              ? "border-transparent hover:bg-opacity-20 hover:bg-mainDark"
              : "bg-mainDark border-mainLight font-medium")
          }
        >
          <TicketIcon className="w-5 ml-3" />
          <p className="p-2 pl-3 font-medium">{t("Orders")}</p>
        </NavLink>
        <NavLink
          to="/discounts"
          className={({ isActive }) =>
            "flex mx-3 rounded-md " +
            (!isActive
              ? "border-transparent hover:bg-opacity-20 hover:bg-mainDark"
              : "bg-mainDark border-mainLight font-medium")
          }
        >
          <TicketIcon className="w-5 ml-3" />
          <p className="p-2 pl-3 font-medium">{t("Discounts")}</p>
        </NavLink>
        <div className="flex mt-auto mb-8 cursor-pointer ">
          <LogoutIcon className="w-5 ml-3" />
          <p
            className="p-2 pl-3 font-medium"
            onClick={() =>
              logout({
                headers: { refreshtoken: admin.refreshToken || "" },
                body: { all: false },
              })
            }
          >
            {t("Logout")}
          </p>
        </div>
      </div>
    </div>
  );
};

const StoreState = () => {
  const [storeState, setStoreState] = useState(false);
  const { data: storeData } = useQuery(["getStore"], () => API.general.get(), {
    onSuccess: (res) => {
      if ("error" in res) return;
      else {
        setStoreState(res?.storeActive);
      }
    },
  });
  const changeStoreState = useAuth(API.general.update);
  const { mutate: changeActivity } = useMutation(changeStoreState, {
    onSuccess: (res) => {
      if ("error" in res) return;
      else setStoreState(res.storeActive);
    },
  });

  const [open, setOpen] = useState(false);

  return (
    <div className="">
      <ConfirmCloseModal
        open={open}
        setOpen={setOpen}
        confirm={() => {
          if (storeData && !("error" in storeData))
            changeActivity({
              body: {
                ...storeData,
                storeActive: !storeState,
              },
            });
        }}
      />
      <div
        onClick={() => {
          setOpen(true);
        }}
        className={`bg-neutral-900 py-2 px-3 text-white hover:bg-neutral-800 flex justify-between items-center transition-all cursor-pointer
					${storeState ? "border-green-800" : "border-red-400"}
					`}
      >
        <p className="text-sm font-semibold text-white text-opacity-80">Store status:</p>
        {storeState && (
          <div className="flex items-center">
            <p className="text-xs font-bold text-green-600 uppercase">opened</p>
            <div className="w-2 h-2 ml-1 bg-green-600 rounded-full" />
          </div>
        )}
        {!storeState && (
          <div className="flex items-center">
            <p className="text-xs font-bold text-red-600 uppercase">closed</p>
            <div className="w-2 h-2 ml-1 bg-red-600 rounded-full" />
          </div>
        )}
      </div>
    </div>
  );
};

const ConfirmCloseModal = ({ open, setOpen, confirm }: any) => {
  const { t } = useTranslation("chef");

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form>
        <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                Close the store
              </h3>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={() => {
              confirm();
              setOpen(false);
            }}
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("Confirm")}
          </button>
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t("Cancel")}
          </button>
        </div>
      </form>
    </Overlay>
  );
};
