import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type ConfirmationT = SDKTypes['confirmation'];

export class Confirmation {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    getCodeByEmail: {
      query: z.object({ email: z.string().email() }).strict(),
    },
  };

  public getCodeByEmail = (p: { query: ConfirmationT['getCodeByEmail']['query'] }): Promise<ConfirmationT['getCodeByEmail']['return']> => {
    return this.Fetch({ path: '/confirmation/getCodeByEmail', method: 'POST', ...p });
  };

  public getCodeBySMS = (p: { body: ConfirmationT['getCodeBySMS']['body'] }): Promise<ConfirmationT['getCodeBySMS']['return']> => {
    return this.Fetch({ path: '/confirmation/getCodeBySMS', method: 'POST', ...p });
  };
}
