import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type DeviceTokenT = SDKTypes['deviceToken'];

export class DeviceToken {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    get: {
      query: z.object({ token: z.string() }).strict(),
    },
    create: {
      body: z
  .object({
    token: z.string(),
    language: z.enum(["english", "portuguese"]),
    deviceType: z.enum(["IOS", "Android"]),
  })
  .strict(),
    },
    linkUser: {
      body: z
  .object({
    token: z.string(),
    linkType: z.enum(["signup", "signin"]),
    language: z.enum(["english", "portuguese"]),
  })
  .strict(),
    },
    unLinkUser: {
      body: z.object({ token: z.string() }).strict(),
    },
  };

  public get = (p: { query: DeviceTokenT['get']['query'] }): Promise<DeviceTokenT['get']['return']> => {
    return this.Fetch({ path: '/deviceToken/get', method: 'POST', ...p });
  };

  public create = (p: { body: DeviceTokenT['create']['body'] }): Promise<DeviceTokenT['create']['return']> => {
    return this.Fetch({ path: '/deviceToken/create', method: 'POST', ...p });
  };

  public linkUser = (p: { body: DeviceTokenT['linkUser']['body']; headers: DeviceTokenT['linkUser']['headers'] }): Promise<DeviceTokenT['linkUser']['return']> => {
    return this.Fetch({ path: '/deviceToken/linkUser', method: 'POST', ...p });
  };

  public unLinkUser = (p: { body: DeviceTokenT['unLinkUser']['body']; headers: DeviceTokenT['unLinkUser']['headers'] }): Promise<DeviceTokenT['unLinkUser']['return']> => {
    return this.Fetch({ path: '/deviceToken/unLinkUser', method: 'POST', ...p });
  };
}
