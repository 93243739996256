import { SearchIcon, SortAscendingIcon, SortDescendingIcon, XIcon } from "@heroicons/react/outline";
import { APIType } from "api";
import { Overlay } from "components/utils/Overlay";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { API } from "sdk";
import { getDayOfWeekString } from "utils/Date";
import { zodResolver } from "@hookform/resolvers/zod";

import { extract } from "../../utils/Object";
import z from "zod";
import { useController } from "react-hook-form";

import _ from "lodash";

import { useQuery, useAuth, useMutation } from "hooks/useBridge";
import { useForm } from "hooks/useForm";
import { DIETS } from "data/Diets";
import { CATEGORIES } from "data/Categories";

import { MultipleSelect } from "components/form/Select";
import { Pagination } from "components/utils/Pagination";

type ChefsType = APIType["chef"]["getPageByAdmin"]["NoErrorsReturn"];
type ChefType = ChefsType["data"][number];

type GetChefsFilters = APIType["chef"]["getPageByAdmin"]["NoHeadersData"];

const ChefRow = ({ chef, refetch }: { chef: ChefType; refetch: () => any }) => {
  const { i18n } = useTranslation("orders");
  const isVisible = chef.dishes.some((d) => d.stock && d.stock > 0 && d.visibleInApp);
  const chefDishesLength = chef.dishes.length;
  const chefDishesVisibleLength = chef.dishes.filter((d) => d.stock && d.stock > 0 && d.visibleInApp).length;
  const [editAvailabilitiesOpen, setEditAvailabilitiesOpen] = useState(false);

  const authentifiedUpdateChefByAdmin = useAuth(API.chef.updateByAdmin);
  const { mutate: updateChefByAdmin } = useMutation(authentifiedUpdateChefByAdmin, {
    onSuccess: () => refetch(),
  });

  return (
    <div className="grid w-full grid-cols-12 gap-4 py-2 border-b border-gray-50">
      <EditAvailabilitiesModal
        open={editAvailabilitiesOpen}
        setOpen={setEditAvailabilitiesOpen}
        chef={chef}
        refetch={refetch}
      />
      <div className="flex items-center col-span-5 gap-4">
        {chef.dishes[0]?.image ? (
          <img src={chef.dishes[0].image} className="flex-shrink-0 object-cover w-16 h-16 rounded-md" />
        ) : (
          <div className="flex-shrink-0 object-cover w-16 h-16 rounded-md bg-mainLight bg-opacity-20" />
        )}
        <div className="relative flex-shrink-0">
          {chef.profileImage ? (
            <img src={chef.profileImage} className="flex-shrink-0 object-cover w-16 h-16 rounded-md" />
          ) : (
            <div className="flex-shrink-0 object-cover w-16 h-16 rounded-md bg-mainLight bg-opacity-20" />
          )}
          <div
            className={`absolute -top-1 -right-1 w-3 h-3 bg-green-600 rounded-full border border-white ${
              isVisible ? "bg-green-600" : "bg-red-700"
            }`}
          />
        </div>
        <Link className="flex flex-col flex-0" to={`/chefs/${chef._id}`}>
          <p className="font-bold text-main">
            {chef.firstName} {chef.lastName}
          </p>
          <p className="flex flex-1 text-sm text-gray-500 text text-ellipsis line-clamp-2">{chef.phone}</p>
        </Link>
      </div>
      <div className="flex flex-wrap items-center col-span-3 gap-2">
        {([1, 2, 3, 4, 5, 6, 0] as const)
          .filter((d) => chef.disponibilityDays?.includes(d))
          // .filter((_, i) => i < 3)
          .map((a) => (
            <p className="px-2 py-1 text-sm text-gray-700 bg-gray-100 border border-gray-200 rounded-md">
              {getDayOfWeekString(a, i18n.language).slice(0, 3)}
            </p>
          ))}
      </div>
      <div className="flex items-center col-span-2 cursor-pointer" onClick={() => setEditAvailabilitiesOpen(true)}>
        <p className="text-sm line-clamp-3">{chef.disponibilityString}</p>
      </div>
      <div className="flex items-center justify-end col-span-1">
        <p className="text-sm line-clamp-3">
          {chefDishesVisibleLength}/{chefDishesLength}
        </p>
      </div>
      <div className="flex items-center justify-end col-span-1">
        {/* <p className="text-sm line-clamp-3">{chef.positionVisibility}</p> */}{" "}
        <select
          id="position"
          name="position"
          autoComplete="position"
          onChange={(e) =>
            updateChefByAdmin({
              body: { positionVisibility: parseInt(e.target.value) },
              query: { chefId: chef._id },
            })
          }
          defaultValue={chef.positionVisibility}
          className="block w-12 px-2 py-1 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-main focus:border-main sm:text-sm"
        >
          <option value={0}>⭐</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      </div>
    </div>
  );
};

const Table = () => {
  const { t } = useTranslation("chefs");
  const perPage = 25;

  const getPageByAdmin = useAuth(API.chef.getPageByAdmin);

  const [queryFilters, setQueryFilters] = useState<GetChefsFilters>({
    body: {},
    query: { sortOnCreatedAt: "-1", skip: "0", limit: perPage.toString() },
  });
  const { data, refetch } = useQuery(["chefs", queryFilters], () => getPageByAdmin(queryFilters), {
    keepPreviousData: true,
  });

  const setBodyFilter = <Key extends keyof GetChefsFilters["body"]>(key: Key, value: GetChefsFilters["body"][Key]) =>
    setQueryFilters((qF) => ({ ...qF, body: { ...qF.body, page: 0, [key]: value } }));

  const { current: debouncedSetQueryFilters } = useRef(_.debounce(setBodyFilter, 300));

  const setCategory = (S: typeof CATEGORIES[number][] | undefined) => {
    setBodyFilter("category", S);
  };

  const page = ((queryFilters.query.skip || 0) as number) / perPage;
  const setPage = (p: number) =>
    setQueryFilters((qF) => ({
      ...qF,
      query: {
        ...qF.query,
        skip: (p * perPage).toString(),
        limit: perPage.toString(),
      },
    }));

  const setDiet = (S: typeof DIETS[number][] | undefined) => {
    setBodyFilter("diet", S);
  };

  if (!data) return <div />;

  const total = Math.ceil(data.total / perPage);

  return (
    <div className="p-6 mt-4 bg-white border border-gray-200 rounded-md">
      <div className="flex gap-2 mb-4">
        <div className="relative">
          <input
            id="chefName"
            type="text"
            className="relative block w-64 px-3 py-2 pl-10 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md appearance-none h-9 focus:outline-none focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("Search a chef")}
            onChange={(e) => debouncedSetQueryFilters("chefName", e.target.value)}
          />
          <SearchIcon className="absolute w-5 text-gray-500 top-2 left-3" />
        </div>
        <div className="relative">
          <input
            id="textSearch"
            type="text"
            className="relative block w-64 px-3 py-2 pl-10 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md appearance-none h-9 focus:outline-none focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("Search a dish")}
            onChange={(e) => debouncedSetQueryFilters("textSearch", e.target.value)}
          />
          <SearchIcon className="absolute w-5 text-gray-500 top-2 left-3" />
        </div>
        <div className="flex gap-2 ml-auto">
          <DietSelect diet={queryFilters.body.diet} setDiet={setDiet} />
          <CategorySelect category={queryFilters.body.category} setCategory={setCategory} />
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {queryFilters.body.category?.map((c) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50">
            <p className="text-sm ">{c}</p>
            <XIcon
              className="w-6 h-6 p-1 ml-1 cursor-pointer hover:bg-gray-"
              onClick={() =>
                setBodyFilter(
                  "category",
                  queryFilters.body.category?.filter((c2) => c2 !== c)
                )
              }
            />
          </div>
        ))}
        {queryFilters.body.diet?.map((d) => (
          <div className="flex items-center px-2 py-1 border border-gray-200 rounded-md bg-gray-50">
            <p className="text-sm ">{d}</p>
            <XIcon
              className="w-6 h-6 p-1 ml-1 cursor-pointer hover:bg-gray-"
              onClick={() =>
                setBodyFilter(
                  "diet",
                  queryFilters.body.diet?.filter((d2) => d2 !== d)
                )
              }
            />
          </div>
        ))}
      </div>

      <div className="grid grid-cols-12 gap-4 py-2 border-b border-gray-50">
        <div className="flex col-span-5">
          <div className="w-16 col-span-2">
            <p className="text-xs font-bold uppercase">{t("1st dish")}</p>
          </div>
          <div className="flex flex-1 col-span-6 ml-4 ">
            <p className="text-xs font-bold uppercase">{t("Chef")}</p>
          </div>
        </div>
        <div className="flex flex-1 col-span-3 ">
          <p className="text-xs font-bold uppercase">{t("Availabilities")}</p>
        </div>
        <div className="col-span-2">
          <p className="text-xs font-bold uppercase">{t("Av. note")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-xs font-bold uppercase">{t("Dishes")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-xs font-bold uppercase">{t("Pos.")}</p>
        </div>
      </div>
      {data.data.map((chef) => (
        <ChefRow chef={chef} refetch={refetch} key={chef?._id} />
      ))}
      <div className="flex items-center justify-center w-full">
        <Pagination page={page} setPage={setPage} total={total} />
      </div>
    </div>
  );
};

export const Chefs = () => {
  const { t } = useTranslation("chefs");

  return (
    <div className="w-full px-8 mx-auto">
      <div className="flex items-end mt-8">
        <p className="text-4xl font-bold">{t("Chefs")}</p>
      </div>
      <Table />
    </div>
  );
};

interface EditAvailabilitiesModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  chef: ChefType;
  refetch: () => any;
}

const EditAvailabilitiesModal = ({ open, setOpen, chef, refetch }: EditAvailabilitiesModalProps) => {
  const { t, i18n } = useTranslation("chef");
  const tokenizedUpdateChef = useAuth(API.chef.updateByAdmin);

  const { mutate: updateAvailabilities } = useMutation(tokenizedUpdateChef, {
    onSuccess: () => {
      setOpen(false);
      refetch();
    },
  });

  const { register, handleSubmit, reset, control } = useForm(updateAvailabilities, {
    resolver: zodResolver(
      z.object({
        body: API.chef.zodSchemas.updateByAdmin.body,
        query: z.object({ chefId: z.string() }),
      })
    ),
    defaultValues: {
      body: extract(chef, ["disponibilityString", "disponibilityDays"]),
      query: { chefId: chef._id },
    },
  });

  const { field } = useController({ name: "body.disponibilityDays", control });
  const { value: disponibilityDays, onChange: setDisponibilityDays } = field;

  useEffect(
    () => reset({ body: extract(chef, ["disponibilityString", "disponibilityDays"]), query: { chefId: chef._id } }),
    [chef.disponibilityString, chef.disponibilityDays]
  );

  return (
    <Overlay open={open} setOpen={setOpen}>
      <form className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4" id="edit-profile" onSubmit={handleSubmit}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6q" id="modal-title">
              {t("Edit availabilities")}: {chef.firstName} {chef.lastName}
            </h3>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="first-name" className="mt-4 font-semibold ">
            {t("Availibilities")}
          </label>
          <div className="flex flex-wrap gap-2 mt-2">
            {([1, 2, 3, 4, 5, 6, 0] as const).map((d) => (
              <p
                className={`p-1 px-2 cursor-pointer border  rounded-md ${
                  disponibilityDays?.includes(d) ? "bg-main text-white" : "bg-gray-50 border-gray-200"
                }`}
                onClick={() =>
                  setDisponibilityDays(
                    disponibilityDays?.includes(d)
                      ? disponibilityDays.filter((d2) => d2 !== d)
                      : [...(disponibilityDays || []), d]
                  )
                }
              >
                {getDayOfWeekString(d, i18n.language)}
              </p>
            ))}
          </div>
          <textarea
            id="availabilities"
            rows={5}
            className="block w-full mt-4 border border-gray-300 rounded-md shadow-sm focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("Availibilities")}
            defaultValue={""}
            {...register("body.disponibilityString")}
          />
        </div>
      </form>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-main hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:ml-3 sm:w-auto sm:text-sm"
          form="edit-profile"
        >
          {t("Save")}
        </button>
        <button
          type="button"
          onClick={() => {
            setOpen(false);
            reset();
          }}
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          {t("Cancel")}
        </button>
      </div>
    </Overlay>
  );
};

const CategorySelect = ({
  category,
  setCategory,
}: {
  category: typeof CATEGORIES[number][] | undefined;
  setCategory: (c: typeof CATEGORIES[number][] | undefined) => void;
}) => {
  const { t: tCategory } = useTranslation("categories");
  const { t } = useTranslation("dishes");

  return (
    <MultipleSelect
      options={CATEGORIES.map((c) => ({ value: c, label: tCategory(c) } as const))}
      selected={category}
      setSelected={setCategory}
      placeholder={t("Select categories")}
    />
  );
};

const DietSelect = ({
  diet,
  setDiet,
}: {
  diet: typeof DIETS[number][] | undefined;
  setDiet: (c: typeof DIETS[number][] | undefined) => void;
}) => {
  const { t: tDiet } = useTranslation("diets");
  const { t } = useTranslation("dishes");

  return (
    <MultipleSelect
      options={DIETS.map((d) => ({ value: d, label: tDiet(d) } as const))}
      selected={diet}
      setSelected={setDiet}
      placeholder={t("Select diets")}
    />
  );
};
