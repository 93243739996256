import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useAuth, useQuery } from "hooks/useBridge";
import { useGlobalState } from "hooks/useGlobalState";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { API } from "sdk";

export const Order = () => {
  const { t, i18n } = useTranslation("order");
  const { id } = useParams();
  const [admin] = useGlobalState("admin");

  const getByAdmin = useAuth(API.order.getByAdmin);
  const { data: order, isFetchedAfterMount } = useQuery(["order", id, admin.isAuthenticated], () =>
    getByAdmin({ query: { orderId: id || "" } })
  );

  if (!order || !isFetchedAfterMount) return <div />;

  if (!order || !isFetchedAfterMount) return <div />;
  const chefs = order.items
    .map((i) => ({ name: i.chefName, _id: i.chefId, image: i.chefImage }))
    .reduce(
      (acc: { name: string; _id: string; image: string }[], chef) =>
        acc.some((c) => c._id === chef._id) ? acc : [...acc, chef],
      []
    );
  return (
    <div>
      <Header />
      <div className="flex items-center max-w-3xl py-4 mx-auto">
        <p className="text-4xl font-bold">
          {t("Order")} #{order.orderNumber}
        </p>
        <div className="ml-3">
          {order.deliveryStatus === "Completed" ? (
            <p className="px-2 py-2 text-sm text-green-700 bg-green-100 rounded-md">{t("Completed")}</p>
          ) : order.deliveryStatus === "Arrived" ? (
            <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Arrived")}</p>
          ) : order.deliveryStatus === "Created" ? (
            <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Created")}</p>
          ) : order.deliveryStatus === "Dispatched" ? (
            <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Dispatched")}</p>
          ) : order.deliveryStatus === "Pickup" ? (
            <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Pickup")}</p>
          ) : order.deliveryStatus === "Underway" ? (
            <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Underway")}</p>
          ) : (
            <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Waiting")}</p>
          )}
        </div>
      </div>
      <div className="max-w-3xl p-8 mx-auto bg-white border border-gray-200">
        <p className="text-lg font-bold">
          <span className="font-normal">{t("Client")}</span>: {order.userName}{" "}
        </p>
        {order.userEmail && (
          <a className="text-xs font-normal text-gray-500 hover:underline" href={`mailto:${order.userEmail}`}>
            Email: {order.userEmail}
          </a>
        )}
        {order.userPhone && (
          <a className="ml-4 text-xs font-normal text-gray-500 hover:underline" href={`tel:${order.userPhone}`}>
            Tel: {order.userPhone}
          </a>
        )}
        <div className="grid grid-cols-2 mt-8">
          <div className="col-span-1">
            <p className="text-sm">
              {t("Address")}: {order.deliveryAddress?.name || t("No address")}
            </p>
            {order.deliveryAddress?.details && (
              <p className="text-sm">
                {t("Details")}: {order.deliveryAddress?.details}
              </p>
            )}
            {order.deliveryAddress?.instructions && (
              <p className="text-sm">
                {t("Instructions")}: {order.deliveryAddress?.instructions}
              </p>
            )}
          </div>
          <div className="flex justify-end col-span-1">
            <p className="text-sm ">
              {t("Date")}:{" "}
              {new Date(order.createdAt).toLocaleDateString(i18n.language, {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
          {order.driverName && (
            <div className="mt-4">
              <p className="text-sm font-normal text-gray-800">
                {t("Driver")}: {order.driverName}
              </p>
              <p className="text-sm font-normal text-gray-800">
                {t("Phone")}: {order.driverPhone || t("No phone number provided")}
              </p>
            </div>
          )}
          {chefs.map((c) => {
            return (
              <div className="col-span-2">
                <p className="col-span-2 mt-8 text-lg font-bold">{c.name}</p>
                <div className="grid grid-cols-12 pb-2 mt-4 border-b border-gray-50">
                  <p className="col-span-2 text-xs font-bold uppercase">{t("Amount")}</p>
                  <p className="col-span-8 text-xs font-bold uppercase">{t("Item")}</p>
                  <p className="flex justify-end col-span-2 text-xs font-bold uppercase">{t("Price")}</p>
                </div>
                {order.items
                  .filter((i) => i.chefId === c._id)
                  .map((d) => (
                    <div className="grid grid-cols-12 py-3 border-b border-gray-50">
                      <p className="col-span-2 text-xs text-gray-700 ">{d.quantity}</p>
                      <p className="col-span-8 text-xs text-gray-700 ">{d.dishName}</p>
                      <p className="flex justify-end col-span-2 text-xs text-gray-700 ">
                        {(d.pricePerUnit / 100).toFixed(2)}
                      </p>
                    </div>
                  ))}
              </div>
            );
          })}
          {order && (
            <div className="col-span-2">
              <p className="mt-8 text-sm font-bold">{t("Notes")}</p>
              <p className="mt-2 text-xs text-gray-500">{order.noteByUser}</p>
            </div>
          )}
          <div className="col-span-1 mt-4" />
          <div className="col-span-1 mt-8">
            <p className="pb-2 font-bold border-b border-gray-50">{t("Payment")}</p>
            <div className="flex py-2">
              <p className="text-sm">{t("Subtotal")}</p>
              <p className="ml-auto text-sm">{((order.totalItemPrice || 0) / 100).toFixed(2)}</p>
            </div>
            {!!order.discountAmount && (
              <div className="flex py-2">
                <p className="text-sm">
                  {t("Promo")} ({order.discountName})
                </p>
                <p className="ml-auto text-sm">{(order.discountAmount / 100).toFixed(2)}€</p>
              </div>
            )}
            <div className="flex py-2">
              <p className="text-sm">{t("Delivery")}</p>
              <p className="ml-auto text-sm">{((order.deliveryPrice || 0) / 100).toFixed(2)}€</p>
            </div>
            <div className="flex py-2">
              <p className="text-sm font-bold">{t("Total paid")}</p>
              <p className="ml-auto text-sm font-bold">{((order.totalPrice || 0) / 100).toFixed(2)}€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="sticky top-0 z-20 flex items-center w-full h-16 px-8 bg-white border-b-2 border-gray-200">
      <Link to="/orders">
        <div className="p-1 border rounded-md">
          <ArrowLeftIcon className="w-6 h-6 text-gray-500" />
        </div>
      </Link>
    </div>
  );
};
