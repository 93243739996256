import { SearchIcon } from "@heroicons/react/outline";
import { APIType } from "api";
import { Select } from "components/form/Select";
import { Pagination } from "components/utils/Pagination";
import { useQuery, useAuth } from "hooks/useBridge";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { API } from "sdk";
import useSound from "use-sound";
import Lottie from "react-lottie";
import NewOrderSound from "../../assets/newOrderSound.mp3";
import CashRegisterSound from "../../assets/cash-register.mp3";
import DoorBellSound from "../../assets/doorbell.mp3";
import ReceptionBellSound from "../../assets/reception-bell.mp3";
import ServiceBellSound from "../../assets/service-bell.mp3";
import lottieJson from "../../assets/refresh.json";
import { PlayIcon, SpeakerLoudIcon, SpeakerOffIcon } from "@radix-ui/react-icons";

const Sounds = [
  { name: "Cash register bell", sound: CashRegisterSound },
  { name: "A table!", sound: NewOrderSound },
  { name: "Door bell", sound: DoorBellSound },
  { name: "Reception bell", sound: ReceptionBellSound },
  { name: "Service bell", sound: ServiceBellSound },
];

const ORDERSTATUS = ["Completed", "Arrived", "Created", "Dispatched", "Pickup", "Underway", "Waiting"] as const;

type OrdersType = APIType["order"]["getPageByAdmin"]["NoErrorsReturn"];
type OrderType = OrdersType["data"][number];

type GetOrdersFilters = APIType["order"]["getPageByAdmin"]["NoHeadersData"];

const OrderRow = ({ order }: { order: OrderType }) => {
  const { t, i18n } = useTranslation("orders");
  const [localOrder, setLocalOrder] = useState<typeof order>(order);
  const updateByAdmin = useAuth(API.order.updateByAdmin);
  const setOrderToPrepared = (isPrepared: boolean) =>
    updateByAdmin({ body: { prepared: isPrepared }, query: { orderId: order._id } }).then((res) => {
      if (!("error" in res)) setLocalOrder(res);
    });

  return (
    <Link className="grid w-full grid-cols-12 gap-4 py-2 border-b border-gray-50" to={`/orders/${order._id}`}>
      <div className="flex items-center col-span-3 gap-4">
        <p className="w-12 overflow-hidden text-xs text-main">{order.orderNumber}</p>
        <input
          id="check"
          readOnly={true}
          name="check"
          checked={localOrder.prepared}
          type="checkbox"
          onClick={(e) => {
            e.preventDefault();
            setOrderToPrepared(!localOrder.prepared);
          }}
          className="w-4 h-4 p-1 border-gray-300 rounded text-main focus:ring-main"
        />
        <p className="text-sm line-clamp-3">{order.userName}</p>
      </div>
      <div className="flex items-center col-span-3 gap-4">
        <div className="flex items-center">
          <p className="text-sm line-clamp-2">
            <span className="font-medium text-main">
              ({order.items.reduce((tot, i) => tot + i.quantity, 0)} items){" "}
            </span>
            {order.items.map((d) => `${d.quantity}x ${d.dishName}`).join(", ")}
          </p>
        </div>
      </div>

      <div className="flex items-center col-span-3">
        <p className="text-sm line-clamp-3">{order.deliveryAddress?.name}</p>
      </div>
      <div className="flex flex-col col-span-1 ">
        {order.orderDate && (
          <>
            <p className="text-sm ">
              {new Date(order.orderDate).toLocaleTimeString(i18n.language, {
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
            <p className="text-sm ">
              {new Date(order.orderDate).toLocaleDateString(i18n.language, {
                day: "numeric",
                month: "numeric",
              })}
            </p>
          </>
        )}
      </div>
      <div className="flex flex-col items-end justify-center col-span-1">
        <p className="text-sm">{((order.totalPrice || 0) / 100).toFixed(2)}€</p>
        {order.discountName && (
          <p
            className="p-1 text-xs break-words rounded-md bg-mainLight line-clamp-1 bg-opacity-20 text-main "
            style={{ maxWidth: "100%" }}
          >
            {order.discountName}
          </p>
        )}
      </div>
      <div className="flex items-center justify-end col-span-1">
        {order.deliveryStatus === "Completed" ? (
          <p className="px-2 py-2 text-sm text-green-700 bg-green-100 rounded-md">{t("Completed")}</p>
        ) : order.deliveryStatus === "Arrived" ? (
          <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Arrived")}</p>
        ) : order.deliveryStatus === "Created" ? (
          <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Created")}</p>
        ) : order.deliveryStatus === "Dispatched" ? (
          <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Dispatched")}</p>
        ) : order.deliveryStatus === "Pickup" ? (
          <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Pickup")}</p>
        ) : order.deliveryStatus === "Underway" ? (
          <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Underway")}</p>
        ) : (
          <p className="px-2 py-2 text-sm text-gray-700 bg-gray-100 rounded-md">{t("Waiting")}</p>
        )}
      </div>
    </Link>
  );
};

interface TableProps {
  page: number;
  setPage: (p: number) => void;
  orders: OrdersType;
  total: number;
  queryFilters: GetOrdersFilters;
  setQueryFilters: Dispatch<SetStateAction<GetOrdersFilters>>;
}

const Table = ({ orders, page, setPage, total, queryFilters, setQueryFilters }: TableProps) => {
  const { t } = useTranslation("orders");

  const setBodyFilter = <Key extends keyof GetOrdersFilters["body"]>(key: Key, value: GetOrdersFilters["body"][Key]) =>
    setQueryFilters((qF) => ({ ...qF, body: { ...qF.body, [key]: value } }));

  const { current: debouncedSetQueryFilters } = useRef(_.debounce(setBodyFilter, 300));

  const setStatus = (S: typeof ORDERSTATUS[number] | undefined) => {
    setBodyFilter("deliveryStatus", S);
  };

  return (
    <div className="">
      <div className="flex">
        <div className="relative ">
          <input
            id="chefName"
            type="text"
            className="relative block w-64 px-3 py-2 pl-10 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md appearance-none h-9 focus:outline-none focus:ring-main focus:border-main sm:text-sm"
            placeholder={t("Search a user")}
            onChange={(e) => debouncedSetQueryFilters("username", e.target.value)}
          />
          <SearchIcon className="absolute w-5 text-gray-500 top-2 left-3" />
        </div>
        <div className="ml-auto">
          <StatusSelect status={queryFilters.body.deliveryStatus} setStatus={setStatus} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 py-2 mt-2 border-b border-gray-50">
        <div className="flex col-span-3">
          <div className="flex flex-1 col-span-6 ml-24 ">
            <p className="text-xs font-bold uppercase">{t("User name")}</p>
          </div>
        </div>
        <div className="col-span-3">
          <p className="text-xs font-bold uppercase">{t("Details")}</p>
        </div>
        <div className="col-span-3">
          <p className="text-xs font-bold uppercase">{t("Address")}</p>
        </div>
        <div className="flex col-span-1">
          <p className="text-xs font-bold uppercase">{t("Date")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-xs font-bold uppercase">{t("Amount")}</p>
        </div>
        <div className="flex justify-end col-span-1">
          <p className="text-xs font-bold uppercase">{t("Status")}</p>
        </div>
      </div>
      {orders.data.map((order) => (
        <OrderRow order={order} key={order._id} />
      ))}
      <div className="flex items-center justify-center w-full">
        <Pagination page={page} setPage={setPage} total={total} />
      </div>
    </div>
  );
};

const StatusSelect = ({
  status,
  setStatus,
}: {
  status: typeof ORDERSTATUS[number] | undefined;
  setStatus: (c: typeof ORDERSTATUS[number] | undefined) => void;
}) => {
  const { t: tCategory } = useTranslation("orderStatus");
  const { t } = useTranslation("orders");

  return (
    <Select
      options={ORDERSTATUS.map((c) => ({ value: c, label: tCategory(c) } as const))}
      selected={status}
      setSelected={setStatus}
      placeholder={t("Select status")}
    />
  );
};

export const Orders = () => {
  const { t } = useTranslation("orders");
  const [sound, setSound] = useState<typeof Sounds[number]["name"]>("Door bell");
  const [reset, setReset] = useState(false);
  const [playSound] = useSound(Sounds.find((s) => s.name === sound)?.sound, { volume: 0.5 });
  const [soundOn, setSoundOn] = useState(true);
  const [play, setPlay] = useState(false);
  const perPage = 25;
  const [queryFilters, setQueryFilters] = useState<GetOrdersFilters>({
    body: {},
    query: { skip: "0", limit: perPage.toString() },
  });

  const [previousOrderNumber, setPreviousOrderNumber] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setReset((reset) => !reset);
    }, 15000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    setPlay(true);
    if (previousOrderNumber && orders?.total && previousOrderNumber < orders?.total && soundOn) playSound();
    setPreviousOrderNumber(orders?.total || 0);
  }, [reset]);

  const getOrders = useAuth(API.order.getPageByAdmin);

  const { data: orders } = useQuery(["orders", queryFilters, reset], () => getOrders(queryFilters), {
    keepPreviousData: true,
  });

  const page = ((queryFilters.query.skip || 0) as number) / perPage;
  const setPage = (p: number) =>
    setQueryFilters((qF) => ({
      ...qF,
      query: {
        ...qF.query,
        skip: (p * perPage).toString(),
        limit: perPage.toString(),
      },
    }));

  return (
    <div className="w-full px-8 mx-auto">
      <div className="flex items-center mt-8">
        <p className="text-4xl font-bold">{t("Orders")}</p>

        <div className="flex items-center ml-auto mr-4">
          <div
            className="p-1 mr-4 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50"
            onClick={() => setSoundOn(!soundOn)}
          >
            {soundOn ? (
              <SpeakerLoudIcon className="w-5 h-5 text-gray-700" />
            ) : (
              <SpeakerOffIcon className="w-5 h-5 text-gray-700" />
            )}
          </div>

          <div
            className="p-1 mr-4 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50"
            onClick={() => playSound()}
          >
            <PlayIcon className="w-5 h-5 text-gray-700" />
          </div>
          <select
            id="sound"
            name="sound"
            onChange={(e) => {
              setSound(e.target.value);
            }}
            value={sound}
            className="block w-full p-5 px-2 py-1 ml-auto mr-3 bg-white border border-gray-300 rounded-md shadow-sm pr-7 focus:border-main focus:outline-none focus:ring-main sm:text-sm"
          >
            {Sounds.map((s) => (
              <option>{s.name}</option>
            ))}
          </select>
        </div>
        <div
          onClick={() => setReset(!reset)}
          className="flex overflow-hidden border border-gray-100 rounded-full cursor-pointer"
        >
          <Lottie
            options={{ animationData: lottieJson }}
            isStopped={!play}
            eventListeners={[
              {
                eventName: "loopComplete",
                callback: () => setPlay(() => false),
              },
            ]}
            style={{ width: 32, height: 32, borderRadius: 999, overflow: "hidden" }}
          />
        </div>
      </div>
      <div className="p-4 mt-4 bg-white border border-gray-200 rounded-md">
        {orders && (
          <Table
            orders={orders}
            page={page}
            setPage={setPage}
            total={Math.ceil(orders.total / perPage)}
            setQueryFilters={setQueryFilters}
            queryFilters={queryFilters}
          />
        )}
      </div>
    </div>
  );
};
