export const CATEGORIES = [
  "African",
  "Asian",
  "Central-American",
  "European",
  "Frozen",
  "Middle-Eastern",
  "Snacks",
  "South-American",
  "South-Asian",
  "Sweets",
  "Others",
] as const;
