import { atom, useRecoilState } from "recoil";

type StateTypes = {
  admin: { isAuthenticated: boolean | null; refreshToken: null | string; accessToken: string | null };
};

const states: { [Key in keyof StateTypes]: { default: StateTypes[Key]; key: Key } } = {
  admin: { default: { isAuthenticated: null, accessToken: null, refreshToken: null }, key: "admin" },
} as const;

const stateAtoms = {
  admin: atom(states.admin),
} as const;

export const useGlobalState = <KeyType extends keyof typeof stateAtoms>(key: KeyType) => {
  return useRecoilState<StateTypes[KeyType]>(stateAtoms[key] as any);
};
