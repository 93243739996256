import _ from "lodash";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";

export const Pagination = ({ page, setPage, total }: { page: number; setPage: (p: number) => void; total: number }) => {
  if (!total || total <= 1) return <div />;
  const pages =
    total <= 7
      ? _.range(0, total)
      : page < 4
      ? [0, 1, 2, 3, 4, -1, total - 1]
      : page > total - 4
      ? [0, -1, total - 5, total - 4, total - 3, total - 2, total - 1]
      : [0, -1, page - 1, page, page + 1, -1, total - 1];

  return (
    <div className="flex gap-2 mt-4">
      {page ? (
        <div
          onClick={() => setPage(page - 1)}
          className={`border rounded-md cursor-pointer hover:bg-gray-50 w-8 h-8 items-center flex justify-center border-gray-200 p-1`}
        >
          <ChevronLeftIcon className="text-sm " />
        </div>
      ) : null}
      {pages.map((p) => (p === -1 ? <p>...</p> : <PageNumber page={page} setPage={setPage} i={p} />))}
      {page !== total - 1 ? (
        <div
          onClick={() => setPage(page + 1)}
          className={`border rounded-md cursor-pointer hover:bg-gray-50 w-8 h-8 items-center flex justify-center border-gray-200 p-1`}
        >
          <ChevronRightIcon className="text-sm " />
        </div>
      ) : null}
    </div>
  );
};

const PageNumber = ({ page, setPage, i }: { page: number; setPage: (p: number) => void; i: number }) => {
  return (
    <div
      onClick={() => setPage(i)}
      className={`border rounded-md cursor-pointer hover:bg-gray-50 w-8 h-8 items-center flex justify-center border-gray-200 p-1 ${
        i === page ? "border-2 border-main" : ""
      }`}
    >
      <p className={`text-sm ${i === page ? "text-gray-900 font-semibold" : "text-gray-700"}`}>{i + 1}</p>
    </div>
  );
};
