import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type BasketT = SDKTypes['basket'];

export class Basket {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    create: {
      body: z
  .object({
    items: z.array(
      z
        .object({
          dishId: z
            .string()
            .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
          dishName: z.string().optional(),
          quantity: z.number().int().gte(0),
          pricePerUnit: z.number().int().gte(0).optional(),
          chefName: z.string().optional(),
          dishImage: z.string().optional(),
          chefImage: z.string().optional(),
          chefId: z
            .string()
            .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
          stock: z.number().optional(),
        })
        .strict()
    ),
    discountName: z.string().optional(),
    token: z.string().optional(),
  })
  .strict(),
    },
    updateAndRefresh: {
      body: z
  .object({
    items: z.array(
      z
        .object({
          dishId: z
            .string()
            .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
          dishName: z.string().optional(),
          quantity: z.number().int().gte(0),
          pricePerUnit: z.number().int().gte(0).optional(),
          chefName: z.string().optional(),
          dishImage: z.string().optional(),
          chefImage: z.string().optional(),
          chefId: z
            .string()
            .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
          stock: z.number().optional(),
        })
        .strict()
    ),
    discountName: z.string().optional(),
    noteByUser: z.string().optional(),
    deliveryAddress: z
      .object({
        type: z.enum(["Point"]),
        coordinates: z.array(z.number()).min(2).max(2),
        name: z.string(),
        details: z.string().optional(),
        instructions: z.string().optional(),
      })
      .strict()
      .optional(),
  })
  .strict(),
      query: z
  .object({
    basketId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    assignToMe: {
      query: z
  .object({
    basketId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    getByIdIfNotLoggedAndRefresh: {
      query: z
  .object({
    basketId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    cancel: {
      query: z
  .object({
    basketId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
    cancelPaymentIntent: {
      query: z.object({ stripePaymentIntentId: z.string() }).strict(),
    },
    createOrUpdatePaymentIntentAndRefresh: {
      query: z
  .object({
    basketId: z
      .string()
      .regex(new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)")),
  })
  .strict(),
    },
  };

  public create = (p: { body: BasketT['create']['body']; headers: BasketT['create']['headers'] }): Promise<BasketT['create']['return']> => {
    return this.Fetch({ path: '/basket/create', method: 'POST', ...p });
  };

  public updateAndRefresh = (p: { body: BasketT['updateAndRefresh']['body']; query: BasketT['updateAndRefresh']['query']; headers: BasketT['updateAndRefresh']['headers'] }): Promise<BasketT['updateAndRefresh']['return']> => {
    return this.Fetch({ path: '/basket/updateAndRefresh', method: 'POST', ...p });
  };

  public assignToMe = (p: { query: BasketT['assignToMe']['query']; headers: BasketT['assignToMe']['headers'] }): Promise<BasketT['assignToMe']['return']> => {
    return this.Fetch({ path: '/basket/assignToMe', method: 'POST', ...p });
  };

  /** You can also use this query to check if a basket exists*/
  public getMineAndRefresh = (p: { headers: BasketT['getMineAndRefresh']['headers'] }): Promise<BasketT['getMineAndRefresh']['return']> => {
    return this.Fetch({ path: '/basket/getMineAndRefresh', method: 'POST', ...p });
  };

  /** For security reasons, this query does not give a basket from someone logged*/
  public getByIdIfNotLoggedAndRefresh = (p: { query: BasketT['getByIdIfNotLoggedAndRefresh']['query'] }): Promise<BasketT['getByIdIfNotLoggedAndRefresh']['return']> => {
    return this.Fetch({ path: '/basket/getByIdIfNotLoggedAndRefresh', method: 'POST', ...p });
  };

  public cancel = (p: { query: BasketT['cancel']['query']; headers: BasketT['cancel']['headers'] }): Promise<BasketT['cancel']['return']> => {
    return this.Fetch({ path: '/basket/cancel', method: 'POST', ...p });
  };

  public cancelPaymentIntent = (p: { query: BasketT['cancelPaymentIntent']['query'] }): Promise<BasketT['cancelPaymentIntent']['return']> => {
    return this.Fetch({ path: '/basket/cancelPaymentIntent', method: 'POST', ...p });
  };

  public createOrUpdatePaymentIntentAndRefresh = (p: { query: BasketT['createOrUpdatePaymentIntentAndRefresh']['query']; headers: BasketT['createOrUpdatePaymentIntentAndRefresh']['headers'] }): Promise<BasketT['createOrUpdatePaymentIntentAndRefresh']['return']> => {
    return this.Fetch({ path: '/basket/createOrUpdatePaymentIntentAndRefresh', method: 'POST', ...p });
  };
}
