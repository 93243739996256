import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type GeneralT = SDKTypes['general'];

export class General {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    update: {
      body: z
  .object({
    storeActive: z.boolean().optional(),
    deliveryRadius: z.number().optional(),
    deliveryFees: z.number().optional(),
    minAmountToHaveFreeDelivery: z.number().optional(),
    freeDeliveryOnFirstOrder: z.boolean().optional(),
    appVersion: z.string().optional(),
    removeOnboarding: z.boolean().optional(),
    removeOnboardingSignin: z.boolean().optional(),
  })
  .strict(),
    },
  };

  public update = (p: { body: GeneralT['update']['body']; headers: GeneralT['update']['headers'] }): Promise<GeneralT['update']['return']> => {
    return this.Fetch({ path: '/general/update', method: 'POST', ...p });
  };

  public get = (): Promise<GeneralT['get']['return']> => {
    return this.Fetch({ path: '/general/get', method: 'POST'});
  };
}
