import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type AdminT = SDKTypes['admin'];

export class Admin {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    isEmailInUse: {
      query: z.object({ email: z.string().email() }).strict(),
    },
    signin: {
      body: z
  .object({ email: z.string().email(), password: z.string().min(6) })
  .strict(),
    },
    updateMyPassword: {
      body: z
  .object({ oldPassword: z.string().min(6), newPassword: z.string().min(6) })
  .strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    updateForgottenPassword: {
      body: z
  .object({ email: z.string().email(), password: z.string().min(6) })
  .strict(),
      query: z.object({ code: z.string() }).strict(),
    },
    refreshToken: {
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    logout: {
      body: z.object({ all: z.boolean() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    deleteMyAccount: {
      body: z.object({ password: z.string() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    deleteMyAccountWithoutPassword: {
      body: z.object({ password: z.string() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    signup: {
      body: z
  .object({
    email: z.string().email(),
    password: z.string().min(6),
    firstName: z.string(),
    lastName: z.string(),
  })
  .strict(),
    },
  };

  public isEmailInUse = (p: { query: AdminT['isEmailInUse']['query'] }): Promise<AdminT['isEmailInUse']['return']> => {
    return this.Fetch({ path: '/admin/isEmailInUse', method: 'POST', ...p });
  };

  public signin = (p: { body: AdminT['signin']['body'] }): Promise<AdminT['signin']['return']> => {
    return this.Fetch({ path: '/admin/signin', method: 'POST', ...p });
  };

  public updateMyPassword = (p: { body: AdminT['updateMyPassword']['body']; headers: AdminT['updateMyPassword']['headers'] }): Promise<AdminT['updateMyPassword']['return']> => {
    return this.Fetch({ path: '/admin/updateMyPassword', method: 'POST', ...p });
  };

  public updateForgottenPassword = (p: { body: AdminT['updateForgottenPassword']['body']; query: AdminT['updateForgottenPassword']['query'] }): Promise<AdminT['updateForgottenPassword']['return']> => {
    return this.Fetch({ path: '/admin/updateForgottenPassword', method: 'POST', ...p });
  };

  public refreshToken = (p: { headers: AdminT['refreshToken']['headers'] }): Promise<AdminT['refreshToken']['return']> => {
    return this.Fetch({ path: '/admin/refreshToken', method: 'POST', ...p });
  };

  public logout = (p: { body: AdminT['logout']['body']; headers: AdminT['logout']['headers'] }): Promise<AdminT['logout']['return']> => {
    return this.Fetch({ path: '/admin/logout', method: 'POST', ...p });
  };

  public deleteMyAccount = (p: { body: AdminT['deleteMyAccount']['body']; headers: AdminT['deleteMyAccount']['headers'] }): Promise<AdminT['deleteMyAccount']['return']> => {
    return this.Fetch({ path: '/admin/deleteMyAccount', method: 'POST', ...p });
  };

  public deleteMyAccountWithoutPassword = (p: { body: AdminT['deleteMyAccountWithoutPassword']['body']; headers: AdminT['deleteMyAccountWithoutPassword']['headers'] }): Promise<AdminT['deleteMyAccountWithoutPassword']['return']> => {
    return this.Fetch({ path: '/admin/deleteMyAccountWithoutPassword', method: 'POST', ...p });
  };

  public signup = (p: { body: AdminT['signup']['body'] }): Promise<AdminT['signup']['return']> => {
    return this.Fetch({ path: '/admin/signup', method: 'POST', ...p });
  };
}
