import { SDKTypes } from './dts/src/index';
import { z } from 'zod';

type UserT = SDKTypes['user'];

export class User {
  constructor(private Fetch: any) {}

  public zodSchemas = {
    isEmailInUse: {
      query: z.object({ email: z.string().email() }).strict(),
    },
    signin: {
      body: z
  .object({ email: z.string().email(), password: z.string().min(6) })
  .strict(),
    },
    updateMyPassword: {
      body: z
  .object({ oldPassword: z.string().min(6), newPassword: z.string().min(6) })
  .strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    updateForgottenPassword: {
      body: z
  .object({ email: z.string().email(), password: z.string().min(6) })
  .strict(),
      query: z.object({ code: z.string() }).strict(),
    },
    refreshToken: {
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    logout: {
      body: z.object({ all: z.boolean() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    deleteMyAccount: {
      body: z.object({ password: z.string() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    deleteMyAccountWithoutPassword: {
      body: z.object({ password: z.string() }).strict(),
      query: z.object({ refreshtoken: z.string() }).strict(),
    },
    hello: {
      body: z.object({ name: z.string() }).strict(),
    },
    signup: {
      body: z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().email(),
    password: z.string().min(6),
    language: z.enum(["english", "portuguese"]),
  })
  .strict(),
    },
    signinWithPhone: {
      body: z.object({ code: z.string() }).strict(),
    },
    updatePhoneNumber: {
      body: z.object({ code: z.string() }).strict(),
      query: z
  .object({ firstName: z.string().optional(), lastName: z.string().optional() })
  .strict(),
    },
    addDeviceToken: {
      body: z.object({ deviceToken: z.string() }).strict(),
    },
    removeDeviceToken: {
      body: z.object({ deviceToken: z.string() }).strict(),
    },
    updateMe: {
      body: z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().email().optional(),
    language: z.enum(["english", "portuguese"]).optional(),
    address: z
      .array(
        z
          .object({
            type: z.enum(["Point"]),
            coordinates: z.array(z.number()).min(2).max(2),
            name: z.string(),
            note: z.string().optional(),
          })
          .strict()
      )
      .optional(),
  })
  .strict(),
    },
  };

  public isEmailInUse = (p: { query: UserT['isEmailInUse']['query'] }): Promise<UserT['isEmailInUse']['return']> => {
    return this.Fetch({ path: '/user/isEmailInUse', method: 'POST', ...p });
  };

  public signin = (p: { body: UserT['signin']['body'] }): Promise<UserT['signin']['return']> => {
    return this.Fetch({ path: '/user/signin', method: 'POST', ...p });
  };

  public updateMyPassword = (p: { body: UserT['updateMyPassword']['body']; headers: UserT['updateMyPassword']['headers'] }): Promise<UserT['updateMyPassword']['return']> => {
    return this.Fetch({ path: '/user/updateMyPassword', method: 'POST', ...p });
  };

  public updateForgottenPassword = (p: { body: UserT['updateForgottenPassword']['body']; query: UserT['updateForgottenPassword']['query'] }): Promise<UserT['updateForgottenPassword']['return']> => {
    return this.Fetch({ path: '/user/updateForgottenPassword', method: 'POST', ...p });
  };

  public refreshToken = (p: { headers: UserT['refreshToken']['headers'] }): Promise<UserT['refreshToken']['return']> => {
    return this.Fetch({ path: '/user/refreshToken', method: 'POST', ...p });
  };

  public logout = (p: { body: UserT['logout']['body']; headers: UserT['logout']['headers'] }): Promise<UserT['logout']['return']> => {
    return this.Fetch({ path: '/user/logout', method: 'POST', ...p });
  };

  public deleteMyAccount = (p: { body: UserT['deleteMyAccount']['body']; headers: UserT['deleteMyAccount']['headers'] }): Promise<UserT['deleteMyAccount']['return']> => {
    return this.Fetch({ path: '/user/deleteMyAccount', method: 'POST', ...p });
  };

  public deleteMyAccountWithoutPassword = (p: { body: UserT['deleteMyAccountWithoutPassword']['body']; headers: UserT['deleteMyAccountWithoutPassword']['headers'] }): Promise<UserT['deleteMyAccountWithoutPassword']['return']> => {
    return this.Fetch({ path: '/user/deleteMyAccountWithoutPassword', method: 'POST', ...p });
  };

  public getMe = (p: { headers: UserT['getMe']['headers'] }): Promise<UserT['getMe']['return']> => {
    return this.Fetch({ path: '/user/getMe', method: 'POST', ...p });
  };

  public hello = (p: { body: UserT['hello']['body'] }): Promise<UserT['hello']['return']> => {
    return this.Fetch({ path: '/user/hello', method: 'POST', ...p });
  };

  public getPage = (p: { query: UserT['getPage']['query'] }): Promise<UserT['getPage']['return']> => {
    return this.Fetch({ path: '/user/getPage', method: 'POST', ...p });
  };

  public signup = (p: { body: UserT['signup']['body'] }): Promise<UserT['signup']['return']> => {
    return this.Fetch({ path: '/user/signup', method: 'POST', ...p });
  };

  public signinWithPhone = (p: { body: UserT['signinWithPhone']['body'] }): Promise<UserT['signinWithPhone']['return']> => {
    return this.Fetch({ path: '/user/signinWithPhone', method: 'POST', ...p });
  };

  public isPhoneNumberInUse = (p: { body: UserT['isPhoneNumberInUse']['body'] }): Promise<UserT['isPhoneNumberInUse']['return']> => {
    return this.Fetch({ path: '/user/isPhoneNumberInUse', method: 'POST', ...p });
  };

  public updatePhoneNumber = (p: { body: UserT['updatePhoneNumber']['body']; query: UserT['updatePhoneNumber']['query']; headers: UserT['updatePhoneNumber']['headers'] }): Promise<UserT['updatePhoneNumber']['return']> => {
    return this.Fetch({ path: '/user/updatePhoneNumber', method: 'POST', ...p });
  };

  public addDeviceToken = (p: { body: UserT['addDeviceToken']['body']; headers: UserT['addDeviceToken']['headers'] }): Promise<UserT['addDeviceToken']['return']> => {
    return this.Fetch({ path: '/user/addDeviceToken', method: 'POST', ...p });
  };

  public removeDeviceToken = (p: { body: UserT['removeDeviceToken']['body']; headers: UserT['removeDeviceToken']['headers'] }): Promise<UserT['removeDeviceToken']['return']> => {
    return this.Fetch({ path: '/user/removeDeviceToken', method: 'POST', ...p });
  };

  public updateMe = (p: { body: UserT['updateMe']['body']; headers: UserT['updateMe']['headers'] }): Promise<UserT['updateMe']['return']> => {
    return this.Fetch({ path: '/user/updateMe', method: 'POST', ...p });
  };
}
